import React from "react";
import { Container } from "reactstrap";
import CompanyDetails from "./CompanyDetails";
import PageHeader from "../../Component/PageHeader";

const CompanyList = () => {
  document.title = "Danh sách công ty";
  return (
    <React.Fragment>
      <PageHeader title="Danh sách công ty" />
      <section className="section">
        <Container fluid>
          <CompanyDetails />
        </Container>
      </section>
    </React.Fragment>
  );
};

export default CompanyList;
