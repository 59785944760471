import { Col, Row ,Container} from "reactstrap";
import { _url } from "../../configs/url";
import { useState } from "react";
import {getBlogs ,removePost,getCategories} from "../../api/apiBlog";
import React, { useEffect } from "react";
import Pagination from "../Jobs/Pagination/Pagination";
import AddEdit from "./AddEdit";
import { Link } from "react-router-dom";
import { Image } from '@themesberg/react-bootstrap';
import { createSlug } from "../../common/constants/Services";
 const BlogList = (props) => {

  let [postList, setPostList] = useState([]);
  let [categoryList, setCategoryList] = useState([]);
  let [totalPage, setTotalPage] = useState(0);
  let [openEdit, setOpenEdit] = useState(false);
  let [currentPost, setCurrentPost] = useState(null);
  let [modalTitle, setModalTitle] = useState("Thêm bài viết");
  let [currentPage, setCurrentPage] = useState(1);
  const addPost = () => {
    setCurrentPost(null);
    setOpenEdit(true);
  };

  useEffect(() =>{getPostList(1);getCategoryList();},[props]);

  const getPostList = (page) => {
    setCurrentPage(page);
    getBlogs(page).then((res) => {
      if (res && res.data) {
        setPostList(res.data);
        setTotalPage(res.total)
      }
    });
  };

  const getCategoryList = () => {
    getCategories().then((res) => {
      console.log(res);
      if (res) {
        let list = [];
        res.forEach(item => {
          list.push({ value: item.categoryId, label: item.categoryName });
        });

        setCategoryList(list);
      }
    });
  }
  const deletePost = (id) => {
    removePost(id).then((res) => {
      if (res) {
        getPostList(currentPage);
      }
    });
  };
  const updateCurrentPage = (page) => {
    getPostList(page);
  };

  return (
    <React.Fragment>
      <section className="section">
        <Container fluid>
            <Row className="align-items-center">
              <Col md={6}>
              <div className="mb-4 mb-lg-0">
                <h6 className="mb-0">Danh sách bài viết</h6>
              </div>
              </Col>
              <Col md={6} className="text-right">
              <button
                  className="btn btn-primary"
                  onClick={addPost}
                >
                  + Thêm bài viết
                </button>
              </Col>
            </Row>
            <Row>
              <Col md={12}>
                <div className="mt-4">
                  <div className="table-responsive">
                    <table className="table table-centered table-nowrap">
                      <thead>
                        <tr>
                          <th scope="col"></th>
                          <th scope="col">Tiêu đề</th>
                          <th scope="col">Category</th>
                          <th scope="col">Hành động</th>
                        </tr>
                      </thead>
                      <tbody>
                        {postList.map((post, key) => (
                          <tr key={"_job_" + key}>
                            <td>
                              
                              {post.image && <Image className="rounded-3" src={post.image}  height={150} />}
                            
                            </td>
                            <td>
                              <Link target="_blank" to={_url.urlViewPage + createSlug(post.title+"-"+post.id)} className="text-dark ">{post.title}
                              </Link>
                        
                              
                            </td>
                            <td>{post?.postCategory?.categoryName}</td>
                            <td>
                              <button type="button"
                                className="btn btn-primary"
                                onClick={() => {
                                  setCurrentPost(post);
                                  setModalTitle("Sửa bài viết");
                                  setOpenEdit(true);
                                }}
                              >
                                Sửa
                              </button>
                              <button type="button"
                                className="btn btn-danger"
                                onClick={() => {
                                  if (window.confirm("Bạn có chắc chắn muốn xóa bài viết này không?")) {
                                     deletePost(post.id);
                                  }
                                }}
                                >Xóa</button>
                            </td>

                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                </div>
              </Col>
            </Row>
            <Pagination totalPage={totalPage} currentPage={updateCurrentPage} />
        </Container>
        <AddEdit isOpen={openEdit} title = {modalTitle} onClose={() => setOpenEdit(false)} onSave = {() => updateCurrentPage(1) } currentPost={currentPost} categoryList={categoryList} />
      </section>
    </React.Fragment>
  );
}

export default BlogList;