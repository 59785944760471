import React, { useState, useEffect } from 'react';
import { Modal, ModalBody, Row, Col, Card, CardBody, Input } from 'reactstrap';
import { Link } from "react-router-dom";
import userImage2 from "../../assets/images/user/img-02.jpg";
import { getApplyJobs } from "../../api/apiAccount";

const ModalUserInfo = ({ email, employee, countryData, modalState, toggleModal }) => {

    let [avatar, setAvatar] = useState("");
    let [fullname, setFullName] = useState('');
    let [otherName, setOtherName] = useState('');
    let [about, setAbout] = useState('');
    let [phone, setPhone] = useState('');
    let [addressCountry, setAddressCountry] = useState('');


    useEffect(() => {
        getApplyJobs(1, email, "", employee).then((res) => {
            if (res.data.length) {
                res.data.map((item, key) => {
                    setAvatar(item.employee.avatar);
                    setFullName(item.employee.fullName);
                    setOtherName(item.employee.otherName);
                    setAbout(item.employee.about);
                    setPhone(detectPhoneNumber(item.employee.phone));
                    countryData.map((itemcountry, key) => {
                        let cities = itemcountry.cities.filter(c => c.countryID === itemcountry.id);
                        if (cities.length > 0) {
                            cities.map((itemcity, keycity) => {
                                if (itemcity.id == item.employee.cityId) {
                                    setAddressCountry(itemcountry.name + '-' + itemcity.name + ', ' + item.employee.address);
                                }
                            })
                        }
                    })
                })
            }
        })
    }, []);

    const detectPhoneNumber = (phone) => {
        if (phone) {
            let phoneData = phone.split('|');
            let area = phoneData[0] ? '(' + phoneData[0] + ') ' : '';
            let phoneNumber = phoneData[1] ? phoneData[1] : '';
            return area + phoneNumber;
        }
    };


    return (
        <React.Fragment>
            <div
                className="modal fade"
                id="deleteModal"
                tabIndex="-1"
                aria-labelledby="deleteModal"
                aria-hidden="true"
            >
                <Modal isOpen={modalState} toggle={() => toggleModal()} role="dialog" centered size="xl"
                    backdrop="static"
                    scrollable={true}
                >
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title mt-0" id="myModalLabel">
                                Chi tiết ứng viên
                            </h5>
                            <button
                                type="button"
                                className="btn-close"
                                onClick={() => toggleModal()}
                            ></button>
                        </div>
                        <ModalBody>
                            <div>
                                <Row>
                                    <Col lg={4}>
                                        <Card className="side-bar">
                                            <CardBody className="p-4">
                                                <div className="candidate-profile text-center">
                                                    <img
                                                        src={avatar ? avatar : userImage2}
                                                        alt=""
                                                        className="avatar-lg rounded-circle"
                                                    />
                                                    <h6 className="fs-18 mb-0 mt-4">{fullname}</h6>
                                                    <p className="text-muted mb-4">{otherName}</p>
                                                    <ul className="candidate-detail-social-menu list-inline mb-0">
                                                        <li className="list-inline-item">
                                                            <Link to="#" className="social-link">
                                                                <i className="uil uil-twitter-alt"></i>
                                                            </Link>
                                                        </li>
                                                        <li className="list-inline-item">
                                                            <Link to="#" className="social-link">
                                                                <i className="uil uil-whatsapp"></i>
                                                            </Link>
                                                        </li>
                                                        <li className="list-inline-item">
                                                            <Link to="#" className="social-link">
                                                                <i className="uil uil-phone-alt"></i>
                                                            </Link>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </CardBody>

                                            <CardBody className="candidate-contact-details p-4 border-top">
                                                <h6 className="fs-17 fw-semibold mb-3">Chi tiết liên hệ</h6>
                                                <ul className="list-unstyled mb-0">
                                                    <li>
                                                        <div className="d-flex align-items-center mt-4">
                                                            <div className="icon bg-primary-subtle text-primary flex-shrink-0">
                                                                <i className="uil uil-envelope-alt"></i>
                                                            </div>
                                                            <div className="ms-3">
                                                                <h6 className="fs-14 mb-1">Email</h6>
                                                                <p className="text-muted mb-0">{email}</p>
                                                            </div>
                                                        </div>
                                                    </li>
                                                    <li>
                                                        <div className="d-flex align-items-center mt-4">
                                                            <div className="icon bg-primary-subtle text-primary flex-shrink-0">
                                                                <i className="uil uil-map-marker"></i>
                                                            </div>
                                                            <div className="ms-3">
                                                                <h6 className="fs-14 mb-1">Địa chỉ</h6>
                                                                <p className="text-muted mb-0">{addressCountry}</p>
                                                            </div>
                                                        </div>
                                                    </li>
                                                    <li>
                                                        <div className="d-flex align-items-center mt-4">
                                                            <div className="icon bg-primary-subtle text-primary flex-shrink-0">
                                                                <i className="uil uil-phone"></i>
                                                            </div>
                                                            <div className="ms-3">
                                                                <h6 className="fs-14 mb-1">SĐT</h6>
                                                                <p className="text-muted mb-0">{phone}</p>
                                                            </div>
                                                        </div>
                                                    </li>
                                                </ul>
                                            </CardBody>
                                        </Card>
                                    </Col>

                                    <Col lg={8}>
                                        <Card className="candidate-details ms-lg-4 mt-4 mt-lg-0">
                                            <CardBody className="p-4 candidate-personal-detail">
                                                <div>
                                                    <h6 className="fs-17 fw-semibold mb-3">Tổng quan</h6>
                                                    <p className="text-muted mb-2">
                                                        {about}
                                                    </p>
                                                </div>
                                            </CardBody>
                                        </Card>
                                    </Col>
                                </Row>
                            </div>
                        </ModalBody>
                    </div>
                </Modal>
            </div>
        </React.Fragment>
    )
};

export default ModalUserInfo;