import React, { useState, useEffect } from 'react';
import { Modal, ModalBody, ModalFooter, Row, Col } from 'reactstrap';
import { Link } from "react-router-dom";
import { getApplyJobs } from "../../api/apiAccount";
import {getAppliedFile } from "../../api/apiJob";
import { GetCountryList } from "../../api/apiCountry";
import Pagination from "../../pages/Jobs/Pagination/Pagination";
import { checkIconCountry } from "../../common/checkValidate/checkIconCountry";
import ImageDefault from "../../assets/images/featured-job/img-default.webp";


const ModalUserApplyJob = ({ job, modalState, toggleModal }) => {
    let [userApply, setUserApply] = useState();
    let [totalPage, setTotalPage] = useState(1);
    let [countryData, setCountryData] = useState();
    let [alertNoData, setAlertNoData] = useState('');

    useEffect(() => {
        GetCountryList().then((result) => {
            if (result.country) {
                setCountryData(result.country);
                getApplyJobs(1, "", "", "",job).then((res) => {
                    setTotalPage(res.total);
                    if (res.data.length) {
                        const userArr = [];
                        res.data.map((item, key) => {
                            userArr.push({
                                avatar: item.employee.avatar ? item.employee.avatar : ImageDefault,
                                fullName: item.employee.fullName,
                                email: item.employee.email,
                                phone: detectPhoneNumber(item.employee.phone),
                                cityName: getNameCity(result.country, item.employee.cityId),
                                countryImage: checkIconCountry(result.country, item.job.cityId),
                                applyId: item.apply.id,
                                message: item.apply.message,
                                files: getFileName(item.apply.files),
                                status : item.apply.status
                            });
                        })
                        setUserApply(userArr);
                        setAlertNoData('');
                    } else {
                        setAlertNoData('Chưa có ứng viên ứng tuyển');
                    }
                })
            }
        });
    }, []);

    const detectPhoneNumber = (phone) => {
        if (phone) {
            let phoneData = phone.split('|');
            let area = phoneData[0] ? '(' + phoneData[0] + ')' : '';
            let phoneNumber = phoneData[1] ? phoneData[1] : '';
            return area + phoneNumber;
        }
    };

    const getNameCity = (countryList, cityId) => {
        let cityName = '';
        countryList && countryList.map((item, key) => {
            item.cities.map((itemCity, keyCity) => {
                if (itemCity.id == cityId) {
                    cityName = itemCity.name;
                }
            })
        })
        return cityName;
    };

    const updateCurrentPage = (page) => {
        getApplyJobs(page, "", "", "",job).then((res) => {
            setTotalPage(res.total);
            if (res.data.length) {
                const userArr = [];
                res.data.map((item, key) => {
                    userArr.push({
                        avatar: item.employee.avatar ? item.employee.avatar : ImageDefault,
                        fullName: item.employee.fullName,
                        email: item.employee.email,
                        phone: detectPhoneNumber(item.phone),
                        cityName: getNameCity(countryData, item.employee.cityId),
                        countryImage: checkIconCountry(countryData, item.job.cityId),
                        message: item.apply.message,
                        files: getFileName(item.apply.files),
                        applyId: item.apply.id,
                        status : item.apply.status

                    });
                })
                setUserApply(userArr);
            }
        })
    };
    const downloadFile = (key,applyId) => {

        getAppliedFile(key,applyId).then((res) => {
            if(res.url) {
                window.open(res.url, '_blank');
            }
        });
 
    }
    const getFileName = (file) => {
        let maplink = new Map();
        let fileName = file.split(",");
        
        fileName.map((item, key) => {
            let fileDescription = "";
            if (item == 1) {
                fileDescription = "CV";
            } if (item == 2) {
                fileDescription = "Bằng TN cấp cuối cùng";
            } if (item == 3) {
                fileDescription = "Bảng điểm TN cấp cuối cùng";
            } if (item == 4) {
                fileDescription = "Chứng chỉ ngoại ngữ cao nhất, ";
            } if (item == 5) {
                fileDescription = "Hộ chiếu";
            } if (item == 6) {
                fileDescription = "Visa hiện tại hoặc thẻ cư trú";
            } if (item == 7) {
                fileDescription = "Bằng lái xe";
            } if (item == 8) {
                fileDescription = "Chứng chỉ tiếng Anh";
            } if (item == 9) {
                fileDescription = "Chứng chỉ tiếng Nhật";
            } if (item == 10) {
                fileDescription = "Chứng chỉ tiếng Hàn";
            }
            if(fileDescription != ""){
                maplink.set(item, fileDescription);
            }
        });
        
        console.log(maplink);
        return maplink;
    }

    return (
        <React.Fragment>
            <div
                className="modal fade"
                id="deleteModal"
                tabIndex="-1"
                aria-labelledby="deleteModal"
                aria-hidden="true"
            >
                <Modal isOpen={modalState} toggle={() => toggleModal()} role="dialog" centered size="xl"
                    backdrop="static"
                    scrollable={true}
                >
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title mt-0" id="myModalLabel">
                                Ứng viên đã ứng tuyển
                            </h5>
                            <button
                                type="button"
                                className="btn-close"
                                onClick={() => toggleModal()}
                            ></button>
                        </div>
                        <ModalBody>
                            {alertNoData && <div className="section-title text-center">
                                <h3 className="title">{alertNoData}</h3>
                            </div>}
                            <div>
                                <Row>
                                    {userApply && userApply.map((userApplyDetails, key) => (
                                        <div key={key} className="job-box card mt-4">
                                            <div className="p-4">
                                                <Row className="align-items-center">
                                                    <Col md={1}>
                                                        <div className="text-center mb-4 mb-md-0">
                                                            <Link>
                                                                <img
                                                                    src={userApplyDetails.avatar}
                                                                    alt=""
                                                                    className="img-fluid rounded-3"
                                                                    style={{ width: '60px', height: '60px', objectFit: 'cover' }}
                                                                />
                                                            </Link>
                                                            
                                                        </div>
                                                    </Col>

                                                    <Col md={2}>
                                                        <div className="mb-2 mb-md-0">
                                                            <h5 className="fs-18 mb-1">
                                                                <Link className="text-dark">
                                                                    {userApplyDetails.fullName}
                                                                </Link>
                                                            </h5>
                                                            <Link className="text-dark">
                                                                    {userApplyDetails.email}
                                                                </Link>
                                                        </div>
                                                    </Col>

                                                    
                                                    <Col md={2}>
                                                        <div className="d-flex mb-2">
                                                                <div className="flex-shrink-0">
                                                                    <i className="mdi mdi-map-marker text-primary me-1"></i>
                                                                </div>
                                                                <div style={{ display: "flex", flexDirection: "row" }}>
                                                                    <img src={userApplyDetails.countryImage} style={{ marginTop: 3 }} height="18" />
                                                                    <p className="text-muted mb-0" style={{ paddingLeft: 5 }}>{userApplyDetails.cityName}</p>
                                                                </div>
                                                            </div>
                                                        <div className="mb-2 mb-md-0">
                                                            <h5 className="fs-18 mb-1">
                                                                <Link className="text-dark">
                                                                    {userApplyDetails.phone}
                                                                </Link>
                                                            </h5>
                                                        </div>
                                                    </Col>
                                                    <Col md={3}>
                                                    {userApplyDetails.message}
                                                    </Col>
                                                    <Col md={2}>
                                        <div className="mb-2 mb-md-0">

                                                {userApplyDetails.files.size > 0 && 
                                                    
                                                    Array.from(userApplyDetails.files.entries()).map(([key, value]) => (
                                                        <Link  className="badge bg-success-subtle text-success fs-13 mt-1 mx-1" key={key} onClick={() => downloadFile(key, userApplyDetails.applyId)} >
                                                            {value}
                                                        </Link>
                                                    ))
                                                }

                                               
                                        </div>
                                    </Col>
                                    <Col md={2} className='text-center'>
                                                {userApplyDetails.status == 0 ? <span className="badge bg-warning">Chưa xem</span> 
                                                : userApplyDetails.status == 1 ? <span className="badge bg-info">Đã nhận</span>
                                                : userApplyDetails.status == 2 ? <span className="badge bg-info">Đang phỏng vấn</span>
                                                : userApplyDetails.status == 3 ? <span className="badge bg-info">Đã phỏng vấn</span>
                                                : userApplyDetails.status == 4 ? <span className="badge bg-success">Tuyển dụng</span>
                                                : <span className="badge bg-danger">Từ chối</span>}  
                                    </Col>
                                                </Row>
                                            </div>
                                        </div>
                                    ))}
                                </Row>
                            </div>
                        </ModalBody>
                        {userApply && <ModalFooter>
                            <Pagination totalPage={totalPage} currentPage={updateCurrentPage} />
                        </ModalFooter>}
                    </div>
                </Modal>
            </div>
        </React.Fragment>
    )
};

export default ModalUserApplyJob;