import React, { useState, useEffect } from "react";
import { Col, Row, Modal, ModalBody, DropdownToggle, DropdownMenu, DropdownItem, UncontrolledButtonDropdown } from "reactstrap";
import { Container } from "react-bootstrap";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { getUsers, deleteUser, confirmPhoneNumber, updateStatus } from "../../../api/apiAccount";
import PlaceholderJobFilter from "../../../common/placeholder/PlaceholderJobFilter";
import Pagination from "../../Jobs/Pagination/Pagination";
import ImageDefault from "../../../assets/images/featured-job/img-default.webp";
import PageHeader from "../../Component/PageHeader";
import ModalJobApply from "../../../common/modal/ModalJobApply";
import ModalUserInfo from "../../../common/modal/ModalUserInfo";
import { GetCountryList } from "../../../api/apiCountry";

const UserList = () => {
  const { t } = useTranslation("translation");
  document.title = "Danh sách ứng viên";

  let [id, setId] = useState(0);
  let [action, setAction] = useState();
  let [email, setEmail] = useState("");
  let [userName, setUserName] = useState("");
  let [users, setUsers] = useState([]);
  let [totalPage, setTotalPage] = useState(1);
  let [alertNoData, setAlertNoData] = useState('');
  const [modal, setModal] = useState(false);
  const openModal = () => setModal(!modal);
  const [modalPhone, setModalPhone] = useState(false);
  const openModalPhone = () => setModalPhone(!modalPhone);
  const [modalListApply, setModalListApply] = useState(false);
  const openModalListApply = (mail) => {
    setEmail(mail);
    setModalListApply(!modalListApply);
  };

  const [modalViewProfile, setModalViewProfile] = useState(false);
  const openModalViewProfile = (mail, username) => {
    setEmail(mail);
    setUserName(username);
    setModalViewProfile(!modalViewProfile);
  }
  let [countryListAll, setCountryListAll] = useState();

  useEffect(() => {
    getUserList(1);
  }, []);

  useEffect(() => {
    GetCountryList().then((result) => {
      if (result.country) {
        setCountryListAll(result.country);
      }
    })
  }, []);

  const getUserList = (page) => {
    getUsers(page).then((res) => {
      setTotalPage(res.total);
      if (res.data.length) {
        const userArr = [];
        res.data.map((item, key) => {
          userArr.push({
            avatar: item.avatar ? item.avatar : ImageDefault, fullName: item.fullName, userName: item.userName,
            cityId: item.cityId, email: item.email, phone: detectPhoneNumber(item.phone),
            emailConfirmed: item.emailConfirmed, phoneNumberConfirmed: item.phoneNumberConfirmed, locked: item.locked
          });
        })
        setUsers(userArr);
        setAlertNoData('');
      } else {
        setAlertNoData('Không có kết quả tìm kiếm!');
      }
    });
  }

  const detectPhoneNumber = (phone) => {
    if (phone) {
      let phoneData = phone.split('|');
      let area = phoneData[0] ? '(' + phoneData[0] + ')' : '';
      let phoneNumber = phoneData[1] ? phoneData[1] : '';
      return area + phoneNumber;
    }
  };

  const updateCurrentPage = (page) => {
    getUserList(page);
  };

  const handleDeleteUser = (userId) => {
    setId(userId);
    openModal();
  }
  const handleLockUser = (userId) => {
    setId(userId);
    setAction('lock');

    openModal();
  }

  const handleUnLockUser = (userId) => {
    setId(userId);
    setAction('unlock');
    openModal();
  }
  const handleConfirmDeleteUser = () => {
    let data = {
      id: id
    }
    deleteUser(data).then((res) => {
      if (res.status == 200) {
        getUserList(1);
      }
    })
    openModal();
  }

  const handleConfirm = (userId) => {
    setId(userId);
    openModalPhone();
  }

  const handleConfirmPhone = () => {
    let data = {
      id: id
    }
    confirmPhoneNumber(data).then((res) => {
      if (res.status == 200) {
        getUserList(1);
      }
    })
    openModalPhone();
  }
  const handleUpdateUser = () => {
    let data = {
      Id: id,
      Action: action
    };
    updateStatus(data).then((res) => {
      if (res.status == 200) {
        getUserList(1);
      }
    })
    openModal();
  }
  return (
    <React.Fragment>
      {modalListApply && <ModalJobApply email={email} modalState={modalListApply} toggleModal={openModalListApply} />}
      {modalViewProfile && <ModalUserInfo email={email} employee={userName} countryData={countryListAll} modalState={modalViewProfile} toggleModal={openModalViewProfile} />}
      <PageHeader title="Danh sách tài khoản" />
      <section className="section">
        <Container fluid>
          <Row className="justify-content-center">
            {alertNoData && <div className="section-title text-center">
              <h3 className="title">{alertNoData}</h3>
            </div>}
            <div className="mt-0">
              <div className="p-4">
                <Row className="align-items-center">
                  <Col md={1} className="text-center">
                    <div className="mb-2 mb-md-0">
                      <h5 className="fs-14 mb-1">
                        <Link className="text-dark">
                          Ảnh đại diện
                        </Link>
                      </h5>
                    </div>
                  </Col>
                  <Col md={3} className="text-left">
                    <div className="mb-2 mb-md-0">
                      <h5 className="fs-14 mb-1">
                        <Link className="text-dark">
                          Tên Ứng viên
                        </Link>
                      </h5>
                    </div>
                  </Col>
                  <Col md={3} className="text-left">
                    <div className="mb-2 mb-md-0">
                      <h5 className="fs-14 mb-1">
                        <Link className="text-dark">
                          Email
                        </Link>
                      </h5>
                    </div>
                  </Col>
                  <Col md={2} className="text-left">
                    <div className="mb-2 mb-md-0">
                      <h5 className="fs-14 mb-1">
                        <Link className="text-dark">
                          SĐT
                        </Link>
                      </h5>
                    </div>
                  </Col>
                  <Col md={2} className="text-center">
                    <div className="mb-2 mb-md-0">
                      <h5 className="fs-14 mb-1">
                        <Link className="text-dark">
                          Status
                        </Link>
                      </h5>
                    </div>
                  </Col>
                  <Col md={1} className="text-center">
                    <div className="mb-2 mb-md-0">
                      <h5 className="fs-14 mb-1">
                        <Link className="text-dark">
                          Action
                        </Link>
                      </h5>
                    </div>
                  </Col>
                </Row>
              </div>
            </div>
            {(!users.length && !alertNoData) ? (<PlaceholderJobFilter />) : users.map((userDetail, key) => (
              <div className="job-box card mt-0" key={key}>
                <div className="p-1">
                  <Row className="align-items-center">
                    <Col md={1}>
                      <div className="text-center mb-4 mb-md-0">
                        <Link onClick={() => openModalViewProfile(userDetail.email, userDetail.fullName)}>
                          <img
                            src={userDetail.avatar ? userDetail.avatar : ImageDefault}
                            alt=""
                            className="img-fluid rounded-3"
                            style={{ width: '60px', height: '60px', objectFit: 'cover' }}
                          />
                        </Link>
                      </div>
                    </Col>
                    <Col md={3}>
                      <div className="mb-2 mb-md-0">
                        <h5 className="fs-18 mb-1">
                          {userDetail.fullName}

                        </h5>
                        <Link className="text-dark"
                          onClick={() => openModalListApply(userDetail.email)}
                        >
                          Xem việc đang ứng tuyển
                        </Link>
                      </div>
                    </Col>
                    <Col md={3} style={{ textAlign: "left" }}>
                      <div className="mb-2 mb-md-0">
                        <h5 className="fs-18 mb-1">
                          <Link className="text-dark">
                            {userDetail.email}
                          </Link>
                        </h5>
                      </div>
                    </Col>

                    <Col md={2} style={{ textAlign: "left" }}>
                      <div className="mb-2 mb-md-0">
                        <h5 className="fs-18 mb-1">
                          <Link className="text-dark">
                            {userDetail.phone}
                          </Link>
                        </h5>
                      </div>
                    </Col>

                    <Col md={2} style={{ textAlign: "center" }}>
                      {
                        userDetail.emailConfirmed ?
                          <li
                            className="list-inline-item"
                            data-bs-toggle="tooltip"
                            data-bs-placement="top"
                            title="Đã kích hoạt"
                          >
                            <div className="mb-2 mb-md-0 avatar-sm bg-success-subtle text-success d-inline-block text-center rounded-circle fs-18">
                              <i className="uil uil-check-circle"></i>
                            </div>
                          </li>
                          :

                          <li
                            className="list-inline-item"
                            data-bs-toggle="tooltip"
                            data-bs-placement="top"
                            title="Chưa kích hoạt"
                          >
                            <div className="mb-2 mb-md-0 avatar-sm bg-danger-subtle text-danger d-inline-block text-center rounded-circle fs-20">
                              <i className="uil uil-times-circle"></i>
                            </div>
                          </li>
                      }
                      {
                        userDetail.phoneNumberConfirmed ?
                          <li
                            className="list-inline-item"
                            data-bs-toggle="tooltip"
                            data-bs-placement="top"
                            title="Đã xác nhận"
                          >
                            <div className="mb-2 mb-md-0 avatar-sm bg-success-subtle text-success d-inline-block text-center rounded-circle fs-18">
                              <i className="uil uil-phone-alt"></i>
                            </div>
                          </li>
                          :

                          <li
                            className="list-inline-item"
                            data-bs-toggle="tooltip"
                            data-bs-placement="top"
                            title="Chưa xác nhận"
                          >
                            <div className="mb-2 mb-md-0 avatar-sm bg-danger-subtle text-danger d-inline-block text-center rounded-circle fs-18">
                              <i className="uil uil-phone-times"></i>
                            </div>
                          </li>
                      }
                      {
                        userDetail.locked == true ?
                          <li
                            className="list-inline-item"
                            data-bs-toggle="tooltip"
                            data-bs-placement="top"
                            title="Locked"
                          >
                            <div className="mb-2 mb-md-0 avatar-sm bg-danger-subtle text-danger d-inline-block text-center rounded-circle fs-18">
                              <i className="uil uil-lock"></i>
                            </div>
                          </li>
                          :

                          <li
                            className="list-inline-item"
                            data-bs-toggle="tooltip"
                            data-bs-placement="top"
                            title="Chưa xác nhận"
                          >
                            <div className="mb-2 mb-md-0 avatar-sm bg-success-subtle text-success d-inline-block text-center rounded-circle fs-18">
                              <i className="uil uil-lock-open-alt"></i>
                            </div>
                          </li>
                      }

                    </Col>
                    <Col lg={1} className="align-self-center" style={{ textAlign: "center" }}>

                      <UncontrolledButtonDropdown direction="down">
                        <DropdownToggle caret
                          type="button"
                          tag="a"
                          title={t("managejob.action")}
                        >
                          <span className=" d-inline-block text-center rounded-circle fs-20"><i className=" uil uil-ellipsis-h"></i></span>
                        </DropdownToggle>
                        <DropdownMenu container="body">
                          {!userDetail.phoneNumberConfirmed &&
                            <DropdownItem onClick={() => handleConfirm(userDetail.userName)}>
                              <i className="uil uil-phone-alt"></i>{t("Xác nhận SĐT")}
                            </DropdownItem>
                          }
                          {userDetail.locked == false || userDetail.locked == null ?
                            <DropdownItem onClick={() => handleLockUser(userDetail.userName)}>
                              <i className="uil uil-lock"></i>{t("Khóa user")}
                            </DropdownItem>
                            :
                            <DropdownItem onClick={() => handleUnLockUser(userDetail.userName)}>
                              <i className="uil uil-lock-open-alt"></i>{t("Mở khóa user")}
                            </DropdownItem>

                          }

                          <DropdownItem onClick={() => handleDeleteUser(userDetail.userName)}>
                            <i className="uil uil-trash-alt"></i> {t("Xóa ứng viên")}
                          </DropdownItem>
                        </DropdownMenu>
                      </UncontrolledButtonDropdown>

                    </Col>
                  </Row>
                </div>
              </div>
            ))}

            {/* confirm delete user */}
            <div className="modal-dialog modal-dialog-centered">
              <Modal isOpen={modal} toggle={openModal} centered tabIndex="-1" backdrop="static">
                <div className="modal-header">
                  <h5 className="modal-title" id="staticBackdropLabel">
                    Xóa user?
                  </h5>
                  <button
                    type="button"
                    className="btn-close"
                    data-bs-dismiss="modal"
                    aria-label="Close"
                  ></button>
                </div>
                <ModalBody>
                  <div>
                    <h6 className="text-danger">
                      <i className="uil uil-exclamation-triangle"></i> Cảnh báo: Bạn có chắc chắn muốn {action == 'lock' ? 'Khóa' : (action == 'unlock' ? 'Mở Khóa' : 'Xóa ')}user?
                    </h6>
                    {action == 'delete' &&
                      <p className="text-muted">

                        Mọi thông tin liên quan đến user sẽ bị xóa khỏi hệ thống.
                      </p>
                    }
                  </div>
                </ModalBody>
                <div className="modal-footer">
                  <button
                    type="button"
                    onClick={openModal}
                    className="btn btn-primary btn-sm"
                  >
                    Cancel
                  </button>
                  <button
                    type="button"
                    className="btn btn-danger btn-sm"
                    onClick={action == 'delete' ? handleConfirmDeleteUser : handleUpdateUser}
                  >
                    Ok, {action == 'lock' ? 'Khóa' : (action == 'unlock' ? 'Mở Khóa' : 'Xóa ')}
                  </button>
                </div>
              </Modal>
            </div>

            {/* confirm Phone */}
            <div className="modal-dialog modal-dialog-centered">
              <Modal isOpen={modalPhone} toggle={openModalPhone} centered tabIndex="-1" backdrop="static">
                <div className="modal-header">
                  <h5 className="modal-title" id="staticBackdropLabel">
                    Xác nhận SĐT?
                  </h5>
                  <button
                    type="button"
                    className="btn-close"
                    data-bs-dismiss="modal"
                    aria-label="Close"
                  ></button>
                </div>
                <ModalBody>
                  <div>
                    <h6 className="text-danger">
                      <i className="uil uil-exclamation-triangle"></i> Bạn có chắc chắn muốn xác nhận SĐT?
                    </h6>
                  </div>
                </ModalBody>
                <div className="modal-footer">
                  <button
                    type="button"
                    onClick={openModalPhone}
                    className="btn btn-primary btn-sm"
                  >
                    Cancel
                  </button>
                  <button
                    type="button"
                    className="btn btn-danger btn-sm"
                    onClick={handleConfirmPhone}
                  >
                    Xác nhận
                  </button>
                </div>
              </Modal>
            </div>
            <Pagination totalPage={totalPage} currentPage={updateCurrentPage} />
          </Row>
        </Container>
      </section>
    </React.Fragment>
  );
};

export default UserList;