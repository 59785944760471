import { _url,timeout } from "../configs/url";
import { customFetch } from "../configs/refreshToken";

export async function getBlogs(page) {
    let url = _url.server + _url.apiListBlog + "?page=" + page;
    
    let header = {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
    };
    return timeout(fetch(url, {
        method: 'GET',
        headers: header,
    }).then(res => res.json()));
 
} 

export async function create(data) {
    let url = _url.server + _url.apiCreateBlog;
    let dataResponse = customFetch(url, data, 'POST', '');
    return dataResponse;
}

export async function removePost(id) {
    let url = _url.server + _url.apiDeleteBlog + '/' + id;
    let dataResponse = customFetch(url, {}, 'DELETE', '');
    return dataResponse;
}

export async function getCategories() {
    let url = _url.server + _url.apiGetBlogCat;
    let header = {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
    };
    return timeout(fetch(url, {
        method: 'GET',
        headers: header,
    }).then(res => res.json()));
}