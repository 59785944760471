import React, { useState, useEffect } from 'react';
import { Modal, ModalBody, ModalFooter, Row, Col } from 'reactstrap';
import { Link } from "react-router-dom";
import { getApplyJobs } from "../../api/apiAccount";
import { GetCountryList } from "../../api/apiCountry";
import Pagination from "../../pages/Jobs/Pagination/Pagination";
import { numberFormat } from "../../common/checkValidate/formatMoney";
import { jobTypeList } from "../../common/dropdownList/dropdownData";
import { checkIconIndustry } from "../../common/checkValidate/checkIconIndustry";
import { checkIconCountry } from "../../common/checkValidate/checkIconCountry";

const ModalJobApply = ({ email, modalState, toggleModal }) => {

    let [jobApply, setJobApply] = useState();
    let [totalPage, setTotalPage] = useState(1);
    let [countryData, setCountryData] = useState();
    let [alertNoData, setAlertNoData] = useState('');

    useEffect(() => {
        GetCountryList().then((result) => {
            if (result.country) {
                setCountryData(result.country);
                getApplyJobs(1, email, "", "").then((res) => {
                    setTotalPage(res.total);
                    if (res.data.length) {
                        const jobArr = [];
                        res.data.map((item, key) => {
                            jobArr.push({
                                id: item.job.id,
                                companyImg: item.job.image ? item.job.image : checkIconIndustry(item.job.catId),
                                title: item.job.title, cityId: item.job.cityId,
                                cityName: getNameCity(result.country, item.job.cityId),
                                countryImage: checkIconCountry(result.country, item.job.cityId),
                                salary: numberFormat(item.job.salaryFrom) + ' - ' + numberFormat(item.job.salaryTo) + ' ' + item.job.currency + '/tháng',
                                fullTime: (item.job.jobType === 1 ? false : true),
                                partTime: (item.job.jobType === 0 ? false : true),
                                jobTypeText: (item.job.jobType === 1 ? jobTypeList[2].label : (item.job.jobType === 0 ? jobTypeList[1].label : jobTypeList[0].label)),
                            });
                        })
                        setJobApply(jobArr);
                        setAlertNoData('');
                    } else {
                        setAlertNoData('Chưa có việc làm đã ứng tuyển');
                    }
                })
            }
        });
    }, []);

    const getNameCity = (countryList, cityId) => {
        let cityName = '';
        countryList && countryList.map((item, key) => {
            item.cities.map((itemCity, keyCity) => {
                if (itemCity.id == cityId) {
                    cityName = itemCity.name;
                }
            })
        })
        return cityName;
    };

    const updateCurrentPage = (page) => {
        getApplyJobs(page, email , "", "").then((res) => {
            setTotalPage(res.total);
            if (res.data.length) {
                const jobArr = [];
                res.data.map((item, key) => {
                    jobArr.push({
                        id: item.job.id,
                        companyImg: item.job.image ? item.job.image : checkIconIndustry(item.job.catId),
                        title: item.job.title, cityId: item.job.cityId,
                        cityName: getNameCity(countryData, item.job.cityId),
                        countryImage: checkIconCountry(countryData, item.job.cityId),
                        salary: numberFormat(item.job.salaryFrom) + ' - ' + numberFormat(item.job.salaryTo) + ' ' + item.job.currency + '/tháng',
                        fullTime: (item.job.jobType === 1 ? false : true),
                        partTime: (item.job.jobType === 0 ? false : true),
                        jobTypeText: (item.job.jobType === 1 ? jobTypeList[2].label : (item.job.jobType === 0 ? jobTypeList[1].label : jobTypeList[0].label)),
                    });
                })
                setJobApply(jobArr);
            }
        })
    };

    return (
        <React.Fragment>
            <div
                className="modal fade"
                id="deleteModal"
                tabIndex="-1"
                aria-labelledby="deleteModal"
                aria-hidden="true"
            >
                <Modal isOpen={modalState} toggle={() => toggleModal()} role="dialog" centered size="xl"
                    backdrop="static"
                    scrollable={true}
                >
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title mt-0" id="myModalLabel">
                                Công việc đã ứng tuyển
                            </h5>
                            <button
                                type="button"
                                className="btn-close"
                                onClick={() => toggleModal()}
                            ></button>
                        </div>
                        <ModalBody>
                            {alertNoData && <div className="section-title text-center">
                                <h3 className="title">{alertNoData}</h3>
                            </div>}
                            <div>
                                <Row>
                                    {jobApply && jobApply.map((jobApplyDetails, key) => (
                                        <div key={key} className="job-box card mt-4">
                                            <div className="p-4">
                                                <Row className="align-items-center">
                                                    <Col md={2}>
                                                        <div className="text-center mb-4 mb-md-0">
                                                            <Link>
                                                                <img
                                                                    src={jobApplyDetails.companyImg}
                                                                    alt=""
                                                                    className="img-fluid rounded-3"
                                                                    style={{ width: '60px', height: '60px', objectFit: 'cover' }}
                                                                />
                                                            </Link>
                                                        </div>
                                                    </Col>

                                                    <Col md={3}>
                                                        <div className="mb-2 mb-md-0">
                                                            <h5 className="fs-18 mb-1">
                                                                <Link className="text-dark">
                                                                    {jobApplyDetails.title}
                                                                </Link>
                                                            </h5>
                                                        </div>
                                                    </Col>

                                                    <Col md={2}>
                                                        <div className="d-flex mb-2">
                                                            <div className="flex-shrink-0">
                                                                <i className="mdi mdi-map-marker text-primary me-1"></i>
                                                            </div>
                                                            <div style={{ display: "flex", flexDirection: "row" }}>
                                                                <img src={jobApplyDetails.countryImage} style={{ marginTop: 3 }} height="18" />
                                                                <p className="text-muted mb-0" style={{ paddingLeft: 5 }}>{jobApplyDetails.cityName}</p>
                                                            </div>
                                                        </div>
                                                    </Col>

                                                    <Col md={3}>
                                                        <div>
                                                            <p className="text-muted mb-2">
                                                                {jobApplyDetails.salary}
                                                            </p>
                                                        </div>
                                                    </Col>

                                                    <Col md={2}>
                                                        <div>
                                                            <span
                                                                className={
                                                                    jobApplyDetails.fullTime === true
                                                                        ? "badge bg-success-subtle text-success fs-13 mt-1 mx-1"
                                                                        : jobApplyDetails.partTime === true
                                                                            ? "badge bg-danger-subtle text-danger fs-13 mt-1 mx-1"
                                                                            : jobApplyDetails.freelancer === true
                                                                                ? "badge bg-primary-subtle text-primary fs-13 mt-1 mx-1"
                                                                                : ""
                                                                }
                                                            >
                                                                {jobApplyDetails.jobTypeText}
                                                            </span>
                                                        </div>
                                                    </Col>
                                                </Row>
                                            </div>
                                        </div>
                                    ))}
                                </Row>
                            </div>
                        </ModalBody>
                        {jobApply && <ModalFooter>
                            <Pagination totalPage={totalPage} currentPage={updateCurrentPage} />
                        </ModalFooter>}
                    </div>
                </Modal>
            </div>
        </React.Fragment>
    )
};

export default ModalJobApply;