import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { Modal, ModalBody, Col, Row, Form, Input,DropdownToggle, DropdownMenu, DropdownItem, UncontrolledButtonDropdown } from "reactstrap";
import Select from 'react-select';
import { useTranslation } from "react-i18next";
import Pagination from "../../Jobs/Pagination/Pagination";
import { getAllCompany, deleteUser, confirmPhoneNumber ,updateStatus,updateFeature } from "../../../api/apiAccount";
import { GetCountryList } from "../../../api/apiCountry";
import PlaceholderJobFilter from "../../../common/placeholder/PlaceholderJobFilter";
//Import Job Images
import ImageDefault from "../../../assets/images/featured-job/img-default.webp";
import { _url } from "../../../configs/url";

const CompanyDetails = () => {
  const { t } = useTranslation("translation");
  const colourStyles = {
    control: (base) => ({
      ...base,
      border: 0,
      boxShadow: "none",
      padding: "12px 0 12px 35px",
      margin: "-16px 0 0 -45px",
      borderRadius: "0",
      outline: "none",
    })
  };

  let [id, setId] = useState(0);
  let [companes, setCompanes] = useState([]);
  let [totalPage, setTotalPage] = useState(1);
  let [name, setName] = useState('');
  let [countryId, setCountryId] = useState('');
  let [countryList, setCountryList] = useState();
  let [cityId, setCityId] = useState('');
  let [cityList, setCityList] = useState();
  let [cityListAll, setCityListAll] = useState();
  let [flag, setFlag] = useState(0);
  let [alertNoData, setAlertNoData] = useState('');


  let [modalTitle, setModalTitle] = useState('');
  let [modalContent, setModalContent] = useState('');
  let [modalContent2, setModalContent2] = useState('');
  let [modelAction, setModelAction] =  useState(() => {});
  let [modalButton, setModalButton] = useState('');

  const [modal, setModal] = useState(false);
  const openModal = () => setModal(true);
  const closeModal = () => setModal(false);
  const [modalPhone, setModalPhone] = useState(false);
  const openModalPhone = () => setModalPhone(!modalPhone);

  useEffect(() => {
    getAllCompany(1, 0, 0, "").then((result) => {
      setTotalPage(result.total);
      if (result.data.length) {
        const companesArr = [];
        result.data.map((item, key) => {
          companesArr.push({
            avatar: item.avatar ? item.avatar : ImageDefault, fullName: item.fullName, userName: item.userName,
            cityId: item.cityId, email: item.email, phone: detectPhoneNumber(item.phone),
            emailConfirmed: item.emailConfirmed, phoneNumberConfirmed: item.phoneNumberConfirmed, locked : item.locked,
            userType: item.userType

          });
        })
        setCompanes(companesArr);
      }
    });

    GetCountryList().then((result) => {
      if (result.country) {
        const countryArr = [{ value: -1, label: t("companydetail.quocgia") }, { value: 0, label: 'Tất cả' }];
        const cityArr = [{ value: -1, label: t("companydetail.thanhpho") }];
        const cityListAllArr = [];
        result.country.map((item, key) => {
          setCountryId(countryArr[0]);
          setCityId(cityArr[0]);
          setCityList(cityArr);
          countryArr.push({ value: item.id, label: item.name });
          cityListAllArr.push(item.cities);
        })
        setCountryList(countryArr);
        setCityListAll(cityListAllArr);
      }
    });
  }, [])

  const detectPhoneNumber = (phone) => {
    if (phone) {
      let phoneData = phone.split('|');
      let area = phoneData[0] ? '(' + phoneData[0] + ')' : '';
      let phoneNumber = phoneData[1] ? phoneData[1] : '';
      return area + phoneNumber;
    }
  };

  const loadCompanyData = (page, countryid, cityid, name) => {
    getAllCompany(page, countryid, cityid, name).then((result) => {
      setTotalPage(result.total);
      if (result.data && result.data.length) {
        const companesArr = [];
        result.data.map((item, key) => {
          companesArr.push({
            avatar: item.avatar ? item.avatar : ImageDefault, fullName: item.fullName, userName: item.userName,
            cityId: item.cityId, email: item.email, phone: detectPhoneNumber(item.phone),
            emailConfirmed: item.emailConfirmed, phoneNumberConfirmed: item.phoneNumberConfirmed, locked : item.locked,
            userType: item.userType
          });
        })
        setCompanes(companesArr);
        if (companesArr.length == 0) {
          setAlertNoData('Không có kết quả tìm kiếm!');
        } else {
          setAlertNoData('');
        }
      } else {
        setAlertNoData('Không có kết quả tìm kiếm!');
        setCompanes([]);
      }
    })
  }

  const updateCurrentPage = (page) => {
    if (flag === 0) {
      loadCompanyData(page, 0, 0, "");
    } else {
      loadCompanyData(page, countryId.value, cityId.value, name);
    }
  }

  const handleName = event => {
    setName(event.target.value);
  }

  const handleCountry = event => {
    setCountryId(event);
    cityListAll.map((item, key) => {
      const citieDropdown = item.filter(c => c.countryID == event.value);
      const cityListArr = [{ value: -1, label: 'Thành phố' }];
      if (citieDropdown.length > 0) {
        citieDropdown.map((itemcity, keycity) => {
          if (keycity === 0) {
            setCityId({ value: itemcity.id, label: itemcity.name });
          }
          cityListArr.push({ value: itemcity.id, label: itemcity.name });
        });
        setCityList(cityListArr);
      }
      if (event.value <= 0) {
        setCityId(cityListArr[0]);
        setCityList(cityListArr);
      }
    });
  }

  const handleCity = event => {
    setCityId({ value: event.value, label: event.label });
  }

  const handleFilter = () => {
    //save condition
    setFlag(1);
    loadCompanyData(1, countryId.value, cityId.value, name);
  }

  const handleDeleteUser = (userId) => {
    setModalTitle("Khóa công ty");
    setModalContent("Bạn có chắc chắn muốn khóa công ty này?");
    setModalContent2('Sau khi xác nhận, công ty sẽ có thể sử dụng các chức năng của hệ thống');
    setModalButton("Khóa");
    setModelAction(() => () =>handleConfirmDeleteUser(userId));


    openModal();
  }

  const handleLockUser = (userId) => {
    setModalTitle("Khóa công ty");
    setModalContent("Bạn có chắc chắn muốn khóa công ty này?");
    setModalContent2('Sau khi xác nhận, công ty sẽ có thể sử dụng các chức năng của hệ thống');
    setModalButton("Khóa");

    setModelAction(() => () =>handleUpdateUser(userId,"lock"));
    openModal();
  }

  const handleUnLockUser = (userId) => {
    setModalTitle("Mở khóa công ty");
    setModalContent("Bạn có chắc chắn muốn mở khóa công ty này?");
    setModalContent2('');
    setModalButton('Mở khóa');
    

    setModelAction(() => () =>handleUpdateUser(userId,"unlock"));
    openModal();
  }


  const handleConfirmDeleteUser = (userId) => {
    let data = {
      id: userId
    };
    deleteUser(data).then((res) => {
      if (res.status == 200) {
        loadCompanyData(1, countryId.value, cityId.value, name);
      }
    })
    closeModal();
  }

  
  const handleUpdateUser = (userId,action) => {
    let data = {
      Id: userId,
      Action: action
    };
    updateStatus(data).then((res) => {
      if (res.status == 200) {
        loadCompanyData(1, countryId.value, cityId.value, name);
      }
    })
    closeModal();
  }




  const featuredUser = (userId, userType) => {

    setModalTitle(userType >0 ? 'Set Featured' : 'Remove Featured');
    setModalContent('Bạn có chắc chắn muốn '+(userType >0 ? 'Set Featured' : 'Remove Featured')+'?');
    setModalContent2('');
    setModalButton('Xác nhận');
    setModelAction(() =>() => handleFeaturedUser(userId, userType));
    openModal();
  }
  const handleFeaturedUser = (userId, userType) => {
    let data = {
      id: userId,
      userType: userType
    };
    updateFeature(data).then((res) => {
      if (res.status == 200) {
        loadCompanyData(1, countryId.value, cityId.value, name);
      }
    })
    closeModal();
  }

  const handleConfirm = (userId) => {
    setModalTitle('Xác nhận SĐT');
    setModalContent('Bạn có chắc chắn muốn xác nhận SĐT?');
    setModalContent2('Sau khi xác nhận, công ty sẽ có thể sử dụng các chức năng của hệ thống');
    setModalButton('Xác nhận');
    setModelAction(() => () =>handleConfirmPhone(userId));
    openModalPhone();
  }

  const handleConfirmPhone = (userId) => {
    let data = {
      id: id
    }
    confirmPhoneNumber(data).then((res) => {
      if (res.status == 200) {
        loadCompanyData(1, countryId.value, cityId.value, name);
      }
    })
    openModalPhone();
  }

  return (
    <React.Fragment>
      <Row className="align-items-center mb-4">
        <div className="me-lg-5">
          <div className="job-list-header">
            <Form action="#">
              <Row className="g-2">
                <Col lg={4} md={6}>
                  <div className="filler-job-form">
                    <i className="uil uil-search me-1"></i>
                    <Input
                      value={name}
                      onChange={handleName}
                      type="search"
                      className="form-control filter-input-box"
                      id="exampleFormControlInput1"
                      placeholder={t("companydetail.tencongty")}
                      style={{ marginTop: "-10px" }}
                    />
                  </div>
                </Col>
                <Col lg={3} md={6}>
                  <div className="filler-job-form">
                    <i className="uil uil-location-point"></i>
                    <Select
                      options={countryList}
                      className="react-select-container choices selectForm__inner "
                      value={countryId}
                      onChange={handleCountry}
                      styles={colourStyles}
                      classNamePrefix="react-select"
                    />
                  </div>
                </Col>
                <Col lg={3} md={6}>
                  <div className="filler-job-form">
                    <i className="uil uil-home-alt"></i>
                    <Select
                      options={cityList}
                      className="react-select-container choices selectForm__inner "
                      value={cityId}
                      onChange={handleCity}
                      styles={colourStyles}
                      classNamePrefix="react-select"
                    />
                  </div>
                </Col>
                <Col lg={2} md={6}>
                  <Link onClick={() => handleFilter()} className="btn btn-primary w-100">
                    {t("companydetail.timkiem")}
                  </Link>
                </Col>
              </Row>
            </Form>
          </div>
        </div>
      </Row>

      <Row>
        {alertNoData && <div className="section-title text-center">
          <h3 className="title">{alertNoData}</h3>
        </div>}
        <div className="mt-0">
          <div className="p-4">
            <Row className="align-items-center">
              <Col md={1} className="text-center">
                <div className="mb-2 mb-md-0">
                  <h5 className="fs-14 mb-1">
                    <Link className="text-dark">
                      Ảnh đại diện
                    </Link>
                  </h5>
                </div>
              </Col>
              <Col md={3} className="text-left">
                <div className="mb-2 mb-md-0">
                  <h5 className="fs-14 mb-1">
                    <Link className="text-dark">
                      Tên công ty
                    </Link>
                  </h5>
                </div>
              </Col>
              <Col md={3} className="text-left">
                <div className="mb-2 mb-md-0">
                  <h5 className="fs-14 mb-1">
                    <Link className="text-dark">
                      Email
                    </Link>
                  </h5>
                </div>
              </Col>
              <Col md={2} className="text-left">
                <div className="mb-2 mb-md-0">
                  <h5 className="fs-14 mb-1">
                    <Link className="text-dark">
                      SĐT
                    </Link>
                  </h5>
                </div>
              </Col>
              <Col md={2} className="text-center">
                <div className="mb-2 mb-md-0">
                  <h5 className="fs-14 mb-1">
                    <Link className="text-dark">
                      Status
                    </Link>
                  </h5>
                </div>
              </Col>
              <Col md={1} className="text-center">
                <div className="mb-2 mb-md-0">
                  <h5 className="fs-14 mb-1">
                    <Link className="text-dark">
                      Action
                    </Link>
                  </h5>
                </div>
              </Col>
            </Row>
          </div>
        </div>
        {(!companes.length && !alertNoData) ? (<PlaceholderJobFilter />) : companes.map((companesDetail, key) => (
          <div className="job-box card mt-0" key={key}>
            <div className="p-1">
              <Row className="align-items-center">
                <Col md={1}>
                  <div className="text-center mb-4 mb-md-0">
                    <Link to={_url.urlViewPage + "company/" + companesDetail.userName} target="_blank">
                      <img
                        src={companesDetail.avatar ? companesDetail.avatar : ImageDefault}
                        alt=""
                        className="img-fluid rounded-3"
                        style={{ width: '60px', height: '60px', objectFit: 'cover' }}
                      />
                    </Link>
                  </div>
                </Col>
                <Col md={3}>
                  <div className="mb-2 mb-md-0">
                    <h5 className="fs-18 mb-1">
                      <Link to={_url.urlViewPage + "company/" + companesDetail.userName} target="_blank" className="text-dark">
                        {companesDetail.fullName}
                      </Link>
                    </h5>
                  </div>
                </Col>
                <Col md={3} style={{ textAlign: "left" }}>
                  <div className="mb-2 mb-md-0">
                    <h5 className="fs-18 mb-1">
                      <Link className="text-dark">
                        {companesDetail.email}
                      </Link>
                    </h5>
                  </div>
                </Col>

                <Col md={2} style={{ textAlign: "left" }}>
                  <div className="mb-2 mb-md-0">
                    <h5 className="fs-18 mb-1">
                      <Link className="text-dark">
                        {companesDetail.phone}
                      </Link>
                    </h5>
                  </div>
                </Col>

                <Col md={2} style={{ textAlign: "center" }}>
                {
                    companesDetail.emailConfirmed?
                    <li
                      className="list-inline-item"
                      data-bs-toggle="tooltip"
                      data-bs-placement="top"
                      title="Đã kích hoạt"
                    >
                      <div className="mb-2 mb-md-0 avatar-sm bg-success-subtle text-success d-inline-block text-center rounded-circle fs-18">
                        <i className="uil uil-check-circle"></i>
                      </div>
                    </li>
                  :
                  
                    <li
                      className="list-inline-item"
                      data-bs-toggle="tooltip"
                      data-bs-placement="top"
                      title="Chưa kích hoạt"
                    >
                      <div className="mb-2 mb-md-0 avatar-sm bg-danger-subtle text-danger d-inline-block text-center rounded-circle fs-20">
                        <i className="uil uil-times-circle"></i>
                      </div>
                    </li>
                  }
                  {
                    companesDetail.phoneNumberConfirmed ?
                    <li
                      className="list-inline-item"
                      data-bs-toggle="tooltip"
                      data-bs-placement="top"
                      title="Đã xác nhận"
                    >
                      <div className="mb-2 mb-md-0 avatar-sm bg-success-subtle text-success d-inline-block text-center rounded-circle fs-18">
                        <i className="uil uil-phone-alt"></i>
                      </div>
                    </li>
                  :
                    
                    <li
                      className="list-inline-item"
                      data-bs-toggle="tooltip"
                      data-bs-placement="top"
                      title="Chưa xác nhận"
                    >
                      <div className="mb-2 mb-md-0 avatar-sm bg-danger-subtle text-danger d-inline-block text-center rounded-circle fs-18">
                        <i className="uil uil-phone-times"></i>
                      </div>
                    </li>
                  }
                  {
                    companesDetail.locked == true ?
                    <li
                      className="list-inline-item"
                      data-bs-toggle="tooltip"
                      data-bs-placement="top"
                      title="Locked"
                    >
                      <div className="mb-2 mb-md-0 avatar-sm bg-danger-subtle text-danger d-inline-block text-center rounded-circle fs-18">
                        <i className="uil uil-lock"></i>
                      </div>
                    </li>
                  :
                    
                    <li
                      className="list-inline-item"
                      data-bs-toggle="tooltip"
                      data-bs-placement="top"
                      title="Chưa xác nhận"
                    >
                      <div className="mb-2 mb-md-0 avatar-sm bg-success-subtle text-success d-inline-block text-center rounded-circle fs-18">
                        <i className="uil uil-lock-open-alt"></i>
                      </div>
                    </li>
                  }

                  {companesDetail.userType >0 &&
                    <li
                    className="list-inline-item"
                    data-bs-toggle="tooltip"
                    data-bs-placement="top"
                    title="Featured"
                  >
                    <div className="mb-2 mb-md-0 avatar-sm bg-success-subtle text-success d-inline-block text-center rounded-circle fs-18">
                      <i className="uil uil-star"></i>
                    </div>
                  </li>
                  }
                    
                </Col>
                <Col lg={1} style={{ textAlign: "center" }} className="align-self-center ">
                 
                  <UncontrolledButtonDropdown direction="down">
                        <DropdownToggle caret
                          type="button"
                          tag="a"
                          title={t("managejob.action")}
                        >
                          <span className=" d-inline-block text-center rounded-circle fs-20"><i className=" uil uil-ellipsis-h"></i></span>
                        </DropdownToggle>
                        <DropdownMenu container="body">
                          {!companesDetail.phoneNumberConfirmed &&
                            <DropdownItem onClick={() => handleConfirm(companesDetail.userName)}>
                              <i className="uil uil-phone-alt"></i>{t("Xác nhận SĐT")}
                            </DropdownItem>
                          }
                          { companesDetail.locked == false || companesDetail.locked == null ?
                            <DropdownItem onClick={() => handleLockUser(companesDetail.userName)}>
                            <i className="uil uil-lock"></i>{t("Khóa công ty")}
                          </DropdownItem>
                             :
                             <DropdownItem onClick={() => handleUnLockUser(companesDetail.userName)}>
                            <i className="uil uil-lock-open-alt"></i>{t("Mở khóa công ty")}
                          </DropdownItem>

                          }

                          {companesDetail.userType >0  ?
                            <DropdownItem onClick={() => featuredUser(companesDetail.userName,0)}>
                            <i className="uil uil-star"></i>{t("Bỏ nổi bật")}
                          </DropdownItem>
                             :
                             <DropdownItem onClick={() => featuredUser(companesDetail.userName,1)}>
                            <i className="uil uil-star"></i>{t("Nổi bật")}
                          </DropdownItem>
                          }

                          <DropdownItem onClick={() => handleDeleteUser(companesDetail.userName)}>
                          <i className="uil uil-trash-alt"></i> {t("Xóa công ty")}
                          </DropdownItem>
                        </DropdownMenu>
                      </UncontrolledButtonDropdown>

                  
                </Col>
              </Row>
            </div>
          </div>
        ))}
        <div className="modal-dialog modal-dialog-centered">
          <Modal isOpen={modal}  centered tabIndex="-1" backdrop="static">
            <div className="modal-header">
              <h5 className="modal-title" id="staticBackdropLabel">
                {modalTitle}
              </h5>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
                onClick={() =>closeModal()}
              ></button>
            </div>
            <ModalBody>
              <div>
                <h6 className="text-danger">
                  <i className="uil uil-exclamation-triangle"></i> 
                  {modalContent}
                  
                </h6>
                {modalContent && 
                <p className="text-muted">
                 
                  {modalContent2}
                </p>
                }
              </div>
            </ModalBody>
            <div className="modal-footer">
              <button
                type="button"
                onClick={()=>closeModal()}
                className="btn btn-primary btn-sm"
              >
                Cancel
              </button>
              <button
                type="button"
                className="btn btn-danger btn-sm"
                onClick={ () => modelAction()}
              >
                Ok, {modalButton}
              </button>
            </div>
          </Modal>
        </div>
        {/* confirm Phone */}
        <div className="modal-dialog modal-dialog-centered">
          <Modal isOpen={modalPhone} toggle={openModalPhone} centered tabIndex="-1" backdrop="static">
            <div className="modal-header">
              <h5 className="modal-title" id="staticBackdropLabel">
                Xác nhận SĐT?
              </h5>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <ModalBody>
              <div>
                <h6 className="text-danger">
                  <i className="uil uil-exclamation-triangle"></i> Bạn có chắc chắn muốn xác nhận SĐT?
                </h6>
              </div>
            </ModalBody>
            <div className="modal-footer">
              <button
                type="button"
                onClick={openModalPhone}
                className="btn btn-primary btn-sm"
              >
                Cancel
              </button>
              <button
                type="button"
                className="btn btn-danger btn-sm"
                onClick={handleConfirmPhone}
              >
                Xác nhận
              </button>
            </div>
          </Modal>
        </div>
        <Pagination totalPage={totalPage} currentPage={updateCurrentPage} />
      </Row>
    </React.Fragment>
  );
};

export default CompanyDetails;
