import React from "react";

//Jobs Section
import JobList from "../pages/Jobs/JobList/JobList";
import JobDetails from "../pages/Jobs/JobDetails/JobDetails";
import CreateJob from "../pages/Jobs/CreateJob/CreateJob";
import CompanyList from "../pages/CandidateAndCompany/CompanyList/CompanyList";

//AuthPages
import SignIn from "../pages/ExtraPages/SignIn";
import SignUp from "../pages/ExtraPages/SignUp";
import SignOut from "../pages/ExtraPages/SignOut";
import ResetPassword from "../pages/ExtraPages/ResetPassword";
import ChangePassword from "../pages/ExtraPages/ChangePassword";
import ComingSoon from "../pages/ExtraPages/ComingSoon";
import Error404 from "../pages/ExtraPages/Error404";
import ActiveEmail from "../pages/ExtraPages/ActiveEmail";
import Components from "../pages/ExtraPages/Components/Components";

//profile section(User Profile)
import UserList from "../pages/Users/UserList/UserList";
import MyProfile from "../pages/Profile/MyProfile/MyProfile";
import Account from "../pages/Profile/MyProfile/Account";
import JobMyApplied from "../pages/Profile/MyProfile/JobMyApplied";
import BlogList from "../pages/Blog/BlogList";

//Home Section
const Layout = React.lazy(() => import('../pages/Home/Layout/Layout'));

const userRoutes = [
  //Components Section(Extra Pages)
  { path: "/components", component: <Components /> },

  //Jobs Section
  { path: "/createjob", component: <CreateJob /> },


  //Company Section

  //Home Section
  { path: "/", component: <Layout /> },

  //ViewJob
  { path: "/job/:id", component: <JobDetails /> },
];

const userRoleRoutes=[
  { path: "/myprofile", component: <MyProfile /> },
  { path: "/account", component: <Account /> },
  { path: "/myapplied", component: <JobMyApplied /> },
];

const adminRoleRoutes=[
  { path: "/companylist", component: <CompanyList /> },
  { path: "/userlist", component: <UserList /> },
  { path: "/joblist", component: <JobList /> },
  { path: "/blogs", component: <BlogList /> },
];

const authRoutes = [
  { path: "/error404", component: <Error404 /> },
  { path: "/account/emailconfirm", component: <ActiveEmail /> },
  { path: "/comingsoon", component: <ComingSoon /> },
  { path: "/resetpassword", component: <ResetPassword /> },
  { path: "/changepassword", component: <ChangePassword /> },
  { path: "/signout", component: <SignOut /> },
  { path: "/signup", component: <SignUp /> },
  { path: "/signin", component: <SignIn /> },
];
export { userRoutes, authRoutes, userRoleRoutes, adminRoleRoutes };