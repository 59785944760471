import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { Col, Input, Row, Form, Collapse, Button, Modal, ModalBody,DropdownToggle, DropdownMenu, DropdownItem, UncontrolledButtonDropdown } from "reactstrap";
import { getListJobAll } from "../../../api/apiJob";
import Pagination from "../../Jobs/Pagination/Pagination";
import Select from 'react-select';
import { useTranslation } from "react-i18next";
import CurrencyInput from 'react-currency-input-field';
import PlaceholderJobFilter from "../../../common/placeholder/PlaceholderJobFilter";
import { jobTypeListFilter, experienceRequiredListFilter, jobTypeList } from "../../../common/dropdownList/dropdownData";
import { deleteJob,featuredJob } from "../../../api/apiJob";
import { GetCountryList } from "../../../api/apiCountry";
import { numberFormat } from "../../../common/checkValidate/formatMoney";
import { convertExperience } from "../../../common/checkValidate/convertExperience";
import { checkJobPostTime } from "../../../common/checkValidate/checkJobPostTime";
import { checkIconJobPost } from "../../../common/checkValidate/checkIconJobPost";
import { checkIconIndustry } from "../../../common/checkValidate/checkIconIndustry";
import { checkIconCountry } from "../../../common/checkValidate/checkIconCountry";
import { checkJobPostExpireTime } from "../../../common/checkValidate/checkJobPostExpireTime";
//Images Import
import ImageUrgent from "../../../assets/images/icon-job/urgent.webp";
import { _url } from "../../../configs/url";
import ModalUserApplyJob from "../../../common/modal/ModalUserApplyJob";


const JobVacancyList = () => {
  const { t } = useTranslation("translation");
  const colourStyles = {
    control: (base) => ({
      ...base,
      border: 0,
      boxShadow: "none",
      padding: "12px 0 12px 35px",
      margin: "-16px 0 0 -45px",
      borderRadius: "0",
      outline: "none",
    })
  };
  let [id, setId] = useState(0);
  let [name, setName] = useState('');
  let [salaryTo, setSalaryTo] = useState('');
  let [countryid, setCountryId] = useState('');
  let [countryList, setCountryList] = useState();
  let [cityId, setCityId] = useState('');
  let [cityList, setCityList] = useState();
  let [cityListAll, setCityListAll] = useState();
  let [industry, setIndustry] = useState('');
  let [industryList, setIndustryList] = useState();
  let [jobVacancyList, setJobVacancyList] = useState([]);
  let [experience, setExperience] = useState('');
  let [jobType, setJobType] = useState('');
  let [countryData, setCountryData] = useState();
  let [job, setJob] = useState("");

  let [alertNoData, setAlertNoData] = useState('');
  let [totalPage, setTotalPage] = useState(1);
  let [currentPage, setCurrentPage] = useState(1);


  let [modalTitle, setModalTitle] = useState('');
  let [modalContent, setModalContent] = useState('');
  let [modalContent2, setModalContent2] = useState('');
  let [modelAction, setModelAction] =  useState(() => {});
  let [modalButton, setModalButton] = useState('');



  //Sidebar
  const [toggleFilter, setToggleFilter] = useState(false);
  const [modal, setModal] = useState(false);
  const openModal = () => setModal(true);
  const closeModal = () => setModal(false);
  const [modalListApply, setModalListApply] = useState(false);
  const openModalListApply = (jobname) => {
    setJob(jobname);
    setModalListApply(!modalListApply);
  }


  useEffect(() => {
    GetCountryList().then((result) => {
      if (result.country) {
        const countryArr = [{ value: -1, label: 'Quốc gia' }, { value: 0, label: 'Tất cả' }];
        const cityArr = [{ value: -1, label: 'Thành phố' }];
        const cityListAllArr = [];
        setCountryData(result.country);
        result.country.map((item, key) => {
          if (key == 0) {
            setCountryId(countryArr[0]);
            setCityId(cityArr[0]);
            setCityList(cityArr);
          }
          countryArr.push({ value: item.id, label: item.name });
          cityListAllArr.push(item.cities);
        })
        setCountryList(countryArr);
        setCityListAll(cityListAllArr);
      }
      if (result.category) {
        const industryArr = [{ value: -1, label: 'Ngành nghề' }, { value: 0, label: 'Tất cả' }];
        result.category.map((item, key) => {
          if (key == 0) {
            setIndustry(industryArr[0]);
          }
          industryArr.push({ value: item.id, label: item.nameVi });
        });
        setIndustryList(industryArr);
      }
    });
    setJobType({ value: '-1', label: 'Hình thức làm việc' });
    setExperience({ value: '-1', label: 'Kinh nghiệm' });
    setName("");
  }, [])

  useEffect(() => {
    getListJobAllFilter(1, "", -1, "", -1, "", -1, -1);
  }, [countryData])

  const handleName = event => {
    setName(event.target.value);
  }

  const handleCountry = event => {
    setCountryId(event);
    cityListAll.map((item, key) => {
      const citieDropdown = item.filter(c => c.countryID == event.value);
      if (citieDropdown.length > 0) {
        const cityListArr = [{ value: -1, label: 'Thành phố' }];
        citieDropdown.map((itemcity, keycity) => {
          if (keycity === 0) {
            setCityId({ value: itemcity.id, label: itemcity.name });
          }
          cityListArr.push({ value: itemcity.id, label: itemcity.name });
        });
        setCityList(cityListArr);
      }
    });
  }

  const handleCity = event => {
    setCityId({ value: event.value, label: event.label });
    getListJobAllFilter(1, name, countryid.value, salaryTo, industry.value, event.value, jobType.value, experience.value);
  }

  const handleJobType = event => {
    setJobType({ value: event.value, label: event.label });
    getListJobAllFilter(1, name, countryid.value, salaryTo, industry.value, cityId.value, event.value, experience.value);
  }

  const handleExperienceRequired = event => {
    setExperience({ value: event.value, label: event.label });
    getListJobAllFilter(1, name, countryid.value, salaryTo, industry.value, cityId.value, jobType.value, event.value);
  }

  const handleIndustry = event => {
    setIndustry(event);
  }

  const handleFilter = () => {
    getListJobAllFilter(1, name, countryid.value, salaryTo, industry.value, "", "", "",);
  }

  const handleSalaryTo = value => {
    setSalaryTo(value);
  }

  const getListJobAllFilter = (page, name, countryid, salary, industry, cityId, type, experience) => {
    getListJobAll(page, name, countryid, salary, industry, cityId, type, experience).then((res) => {
      const job = [];
      if (res.data && res.data.length) {
        setTotalPage(res.total);
        res.data.map((item, key) => {
          job.push({
            id: item.id,
            cityName: getNameCity(item.cityId),
            countryImage: checkIconCountry(countryData, item.cityId),
            companyImg: item.image ? item.image : checkIconIndustry(item.catId),
            jobTitle: item.title,
            companyName: item.company,
            location: item.workLocationAddress,
            jobPostTime: checkJobPostTime(item.createdAt),
            jobPostExpireTime: checkJobPostExpireTime(item.dateNeeded),
            iconJobPost: checkIconJobPost(item.dateNeeded) == 2 ? ImageUrgent : "",
            fullTime: (item.jobType === 1 ? false : true),
            partTime: (item.jobType === 0 ? false : true),
            jobTypeText: (item.jobType === 1 ? jobTypeList[2].label : (item.jobType === 0 ? jobTypeList[1].label : jobTypeList[0].label)),
            salary: numberFormat(item.salaryFrom) + ' - ' + numberFormat(item.salaryTo) + ' ' + item.currency + '/tháng',
            addclassNameBookmark: false,
            badges: [],
            experience: convertExperience(item.experienceRequired),
            featured : item.featured,
          });
        });
        setJobVacancyList(job);
        if (job.length == 0) {
          setAlertNoData('Không có kết quả tìm kiếm!');
        } else {
          setAlertNoData('');
        }
      } else {
        setAlertNoData('Không có kết quả tìm kiếm!');
        setJobVacancyList([]);
      }
    });
  }

  const getNameCity = (cityId) => {
    let cityName = '';
    countryData && countryData.map((item, key) => {
      item.cities.map((itemCity, keyCity) => {
        if (itemCity.id == cityId) {
          cityName = itemCity.name;
        }
      })
    })
    return cityName;
  }

  const updateCurrentPage = (page) => {
    setCurrentPage(page);
    getListJobAllFilter(page, name, countryid.value, salaryTo, industry.value, cityId.value, jobType.value, experience.value);
  }

  const handleDeleteJob = (jobId) => {
    setModalTitle('Xóa công việc?');
    setModalContent('Cảnh báo: Bạn có chắc chắn muốn xóa tin tuyển dụng?');
    setModalContent2('Bài đăng việc làm của bạn sẽ bị xóa và ứng viên sẽ không thể nhìn thấy.');
    setModalButton('Ok, xóa');
    setModelAction(()=>() => handleConfirmDeleteJob(jobId));
    openModal();
  }
  const changeFeatured = (jobId,featured) => {

    setModalTitle('Thay đổi trạng thái tin tuyển dụng?');
    setModalContent('Bạn có chắc chắn muốn thay đổi trạng thái tin tuyển dụng sang: ' + (featured == 2 ? 'Uy tín ' : (featured == 1 ?'Nổi bật':'Bình thường')));
    setModalContent2('');
    setModalButton('Ok, thay đổi');
    setModelAction(()=>() => handleConfirmChangeFeatured(jobId,featured));
    openModal();
  }
  const handleConfirmDeleteJob = (jobId) => {
    let data = {
      id: jobId
    }
    deleteJob(data).then((res) => {
      if (res.status == 200) {
        getListJobAllFilter(currentPage, name, countryid.value, salaryTo, industry.value, cityId.value, jobType.value, experience.value);
      }
    })
    closeModal();
  }

  const handleConfirmChangeFeatured = (jobId,featured) => {
    let data = {
      jobId :jobId,
      jobFeatured: featured
    };
    featuredJob(data).then((res) => {
      if (res.status == 200) {
        getListJobAllFilter(currentPage, name, countryid.value, salaryTo, industry.value, cityId.value, jobType.value, experience.value);
      }
    })
    closeModal();
  }

  return (
    <React.Fragment>
      {modalListApply && <ModalUserApplyJob job={job} modalState={modalListApply} toggleModal={openModalListApply} />}
      <Col lg={12}>
        <div className="me-lg-5">
          <div className="job-list-header">
            <Form action="#">
              <Row className="g-2">
                <Col lg={3} md={6}>
                  <div className="filler-job-form">
                    <i className="uil uil-search me-1"></i>
                    <Input
                      value={name}
                      onChange={handleName}
                      type="search"
                      className="form-control filter-input-box"
                      id="exampleFormControlInput1"
                      placeholder="Tên công việc"
                      style={{ marginTop: "-10px" }}
                    />
                  </div>
                </Col>
                <Col lg={4} md={6}>
                  <div className="filler-job-form">
                    <i className="uil uil-location-point"></i>
                    <Select
                      options={countryList}
                      className="react-select-container choices selectForm__inner "
                      value={countryid}
                      onChange={handleCountry}
                      styles={colourStyles}
                      classNamePrefix="react-select"
                    />
                  </div>
                </Col>
                <Col lg={3} md={6}>
                  <div className="filler-job-form">
                    <i className="uil uil-briefcase-alt"></i>
                    <Select
                      options={industryList}
                      className="react-select-container choices selectForm__inner "
                      value={industry}
                      onChange={handleIndustry}
                      styles={colourStyles}
                      classNamePrefix="react-select"
                    />
                  </div>
                </Col>
                <Col lg={2} md={6}>
                  <Link onClick={() => handleFilter()} className="btn btn-primary w-100">
                    Tìm kiếm
                  </Link>
                </Col>
              </Row>
              <Row>
                <Col>
                  <div className="side-bar mt-2 mt-lg-0 mb-3">
                    <div className="accordion" id="accordionExample">
                      <h2 className="accordion-header" style={{ width: '25%', float: 'right' }} id="experienceOne">
                        <Button
                          className="btn btn-primary w-100"
                          onClick={(e) => {
                            e.preventDefault();
                            setToggleFilter(!toggleFilter);
                          }}
                          role="button"
                          id="collapseExample"
                        >
                          <i className="uil uil-filter"></i> Lọc nâng cao
                        </Button>
                      </h2>
                    </div>
                  </div>
                </Col>
              </Row>
              <Collapse isOpen={toggleFilter}>
                <Row className="g-2 mt-2">
                  <Col lg={3} md={6}>
                    <div className="filler-job-form">
                      <i className="uil uil-money-bill"></i>
                      <CurrencyInput
                        groupSeparator=','
                        decimalSeparator='.'
                        style={{ marginTop: "-10px" }}
                        placeholder="Mức lương đến..."
                        id="salaryTo"
                        allowDecimals={false}
                        className="form-control filter-input-box"
                        value={salaryTo}
                        onValueChange={handleSalaryTo}
                        step={10}
                      />
                    </div>
                  </Col>
                  <Col lg={3} md={6}>
                    <div className="filler-job-form">
                      <i className="uil uil-home-alt"></i>
                      <Select
                        options={cityList}
                        className="react-select-container choices selectForm__inner "
                        value={cityId}
                        onChange={handleCity}
                        styles={colourStyles}
                        classNamePrefix="react-select"
                      />
                    </div>
                  </Col>
                  <Col lg={3} md={6}>
                    <div className="filler-job-form">
                      <Select
                        options={jobTypeListFilter}
                        className="react-select-container choices selectForm__inner "
                        value={jobType}
                        onChange={handleJobType}
                        styles={colourStyles}
                        classNamePrefix="react-select"
                      />
                    </div>
                  </Col>
                  <Col lg={3} md={6}>
                    <div className="filler-job-form">
                      <Select
                        options={experienceRequiredListFilter}
                        className="react-select-container choices selectForm__inner "
                        value={experience}
                        onChange={handleExperienceRequired}
                        styles={colourStyles}
                        classNamePrefix="react-select"
                      />
                    </div>
                  </Col>
                </Row>
              </Collapse>
            </Form>
          </div>
          <div>
            {alertNoData && <div className="section-title text-center">
              <h3 className="title">{alertNoData}</h3>
            </div>}
            <div className="mt-0">
              <div className="p-4">
                <Row className="align-items-center">
                  <Col md={1} className="text-center">
                    <div className="mb-2 mb-md-0">
                      <h5 className="fs-14 mb-1">
                        <Link className="text-dark">
                          Ảnh đại diện
                        </Link>
                      </h5>
                    </div>
                  </Col>
                  <Col md={3} className="text-center">
                    <div className="mb-2 mb-md-0">
                      <h5 className="fs-14 mb-1">
                        <Link className="text-dark">
                          Tên công việc
                        </Link>
                      </h5>
                    </div>
                  </Col>
                  <Col md={2} className="text-center">
                    <div className="mb-2 mb-md-0">
                      <h5 className="fs-14 mb-1">
                        <Link className="text-dark">
                          Địa điểm
                        </Link>
                      </h5>
                    </div>
                  </Col>
                  <Col md={2} className="text-center">
                    <div className="mb-2 mb-md-0">
                      <h5 className="fs-14 mb-1">
                        <Link className="text-dark">
                          Ứng viên ứng tuyển
                        </Link>
                      </h5>
                    </div>
                  </Col>
                  <Col md={2} className="text-center">
                    <div className="mb-2 mb-md-0">
                      <h5 className="fs-14 mb-1">
                        <Link className="text-dark">
                          Hình thức làm việc
                        </Link>
                      </h5>
                    </div>
                  </Col>
                </Row>
              </div>
            </div>
            {(!jobVacancyList.length && !alertNoData) ? (<PlaceholderJobFilter />) : jobVacancyList && jobVacancyList.map((jobListDetails, key) => (
              <div className="job-box card mt-0" key={key}>
                <div className="p-1">
                  <Row className="align-items-center">
                    <Col md={1}>
                      <div className="text-center mb-4 mb-md-0">
                        <Link to={_url.urlViewPage + "job/" + jobListDetails.id} target="_blank">
                          <img
                            src={jobListDetails.companyImg}
                            alt=""
                            className="img-fluid rounded-3"
                            style={{ width: '60px', height: '60px', objectFit: 'cover' }}
                          />
                        </Link>
                      </div>
                    </Col>

                    <Col md={3}>
                      <div className="mb-2 mb-md-0">
                        <h5 className="fs-18 mb-1">
                          <Link to={_url.urlViewPage + "job/" + jobListDetails.id} target="_blank" className="text-dark">
                            {jobListDetails.jobTitle}
                          </Link>
                        </h5>
                      </div>
                    </Col>

                    <Col md={2}>
                      <div className="d-flex mb-2">
                        <div className="flex-shrink-0">
                          <i className="mdi mdi-map-marker text-primary me-1"></i>
                        </div>
                        <div style={{ display: "flex", flexDirection: "row" }}>
                          <img src={jobListDetails.countryImage} style={{ marginTop: 3 }} height="18" />
                          <p className="text-muted mb-0" style={{ paddingLeft: 5 }}>{jobListDetails.cityName}</p>
                        </div>
                      </div>
                    </Col>

                    <Col md={2} className="text-center">
                      <div>
                        <Link onClick={() => openModalListApply(jobListDetails.id)} className="badge bg-success-subtle text-success fs-13 mt-1 mx-1">
                          Xem ứng viên
                        </Link>
                      </div>
                    </Col>

                    <Col md={2} className="text-center">
                      <div>
                        <span
                          className={
                            jobListDetails.fullTime === true
                              ? "badge bg-success-subtle text-success fs-13 mt-1 mx-1"
                              : jobListDetails.partTime === true
                                ? "badge bg-danger-subtle text-danger fs-13 mt-1 mx-1"
                                : jobListDetails.freelancer === true
                                  ? "badge bg-primary-subtle text-primary fs-13 mt-1 mx-1"
                                  : ""
                          }
                        >
                          {jobListDetails.jobTypeText}
                        </span>
                      </div>
                    </Col>

                    <Col lg={1} className="align-self-center">
                      
                    {jobListDetails.featured == 2 &&
                    <li
                    className="list-inline-item"
                    data-bs-toggle="tooltip"
                    data-bs-placement="top"
                    title="Uy tín"
                  >
                    <div className="mb-2 mb-md-0 avatar-sm bg-success-subtle text-success d-inline-block text-center rounded-circle fs-18">
                      <i className="uil uil-star"></i>
                    </div>
                  </li>
                  }
                  {jobListDetails.featured == 1 &&
                  <li
                    className="list-inline-item"
                    data-bs-toggle="tooltip"
                    data-bs-placement="top"
                    title="Hot"
                  >
                    <div className="mb-2 mb-md-0 avatar-sm bg-success-subtle text-success d-inline-block text-center rounded-circle fs-18">
                      <i className="uil uil-star"></i>
                    </div>
                  </li>
                  }
                  </Col>
                  <Col lg={1} className="align-self-center">

                      <UncontrolledButtonDropdown direction="down">
                        <DropdownToggle caret
                          type="button"
                          tag="a"
                          title={t("managejob.action")}
                        >
                          <span className=" d-inline-block text-center rounded-circle fs-20"><i className=" uil uil-ellipsis-h"></i></span>
                        </DropdownToggle>
                      
                      <DropdownMenu container="body">

                      {jobListDetails.featured >0 && 
                            <DropdownItem onClick={() => changeFeatured(jobListDetails.id,0)}>
                            <i className="uil uil-star"></i>{t("Set Job Bình thường")}
                          </DropdownItem>
                      }
                      {jobListDetails.featured != 1 && 
                            <DropdownItem onClick={() => changeFeatured(jobListDetails.id,1)}>
                            <i className="uil uil-star"></i>{t("Set Job Nổi bật")}
                          </DropdownItem>
                      }
                      {jobListDetails.featured !=2 && 
                            <DropdownItem onClick={() => changeFeatured(jobListDetails.id,2)}>
                            <i className="uil uil-star"></i>{t("Set Job Uy tín")}
                          </DropdownItem>
                      }
                      <DropdownItem onClick={() => handleDeleteJob(jobListDetails.id)}>
                          <i className="uil uil-trash-alt"></i> {t(" Xoá Job")}
                          </DropdownItem>
                      </DropdownMenu>
                      
                      </UncontrolledButtonDropdown>
                    </Col>
                  </Row>
                </div>
              </div>
            ))}
          </div>
        </div>
        <div className="modal-dialog modal-dialog-centered">
          <Modal isOpen={modal}  centered tabIndex="-1" backdrop="static">
            <div className="modal-header">
              <h5 className="modal-title" id="staticBackdropLabel">
                {modalTitle}
              </h5>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
                onClick={ () => closeModal() }
              ></button>
            </div>
            <ModalBody>
              <div>
                <h6 className="text-danger">
                  <i className="uil uil-exclamation-triangle"></i>
                  {modalTitle}
                </h6>
                <p className="text-muted">
                  {modalContent}
                  <br/>
                  {modalContent2}
                  
                </p>
              </div>
            </ModalBody>
            <div className="modal-footer">
              <button
                type="button"
                onClick={()=>closeModal()}
                className="btn btn-primary btn-sm"
              >
                Cancel
              </button>
              <button
                type="button"
                className="btn btn-danger btn-sm"
                onClick={()=>modelAction()}
              >
                {modalButton}
              </button>
            </div>
          </Modal>
        </div>
        <Pagination totalPage={totalPage} currentPage={updateCurrentPage} />
      </Col>
    </React.Fragment>
  );
};

export default JobVacancyList;
