import React, { useState, useEffect, useRef } from "react";
import {
  Col,
  Row,
  Nav,
  NavLink,
  TabContent,
  TabPane,
  Card,
  Input,
  NavItem,
  CardBody,
  Label,
  Modal,
  ModalBody,
} from "reactstrap";
import { Icon } from '@iconify/react';
import classnames from "classnames";
import userImage2 from "../../../assets/images/user/img-02.jpg";
import { updateProfile, updateAvatar, getAccountInfo, updateFile, getMyFile, downloadFile } from "../../../api/apiAccount";
import { GetCountryList } from "../../../api/apiCountry";
import { checkPhoneNumberFormat } from "../../../common/checkValidate/checkPhoneNumber";
import Alerts from "../../../pages/ExtraPages/Components/Alerts";
import AlertsSuccess from "../../../pages/ExtraPages/Components/AlertsSuccess";
import Spinners from "../../../pages/ExtraPages/Components/Spinners";
import { MESSAGE_VALIDATE } from "../../../common/constants/Messages";
import { phones } from "../../../common/constants/phoneValidate";
import { useTranslation } from "react-i18next";
import { STORAGE_KEYS } from "../../../common/constants/Storage";
import { getStorage } from "../../../common/constants/Services";
import {
  marriageList, genderList, certificateOfEligibilityList, bloodTypeList,
  preferredHandList, eyesightList, positionList, favoriteSubjectList, personalityList, strongPointList, weakpointList,
  educationLevelList, graduatestatusList, visaTypeList, qualificationList
} from "../../../common/dropdownList/dropdownData";
import { Link } from "react-router-dom";
import Select from 'react-select';
import Moment from 'moment';
import { PDFDownloadLink, PDFViewer } from "@react-pdf/renderer";
import template1 from "../../../assets/images/ungvien/template1.png";
import template2 from "../../../assets/images/ungvien/template2.png";
import template3 from "../../../assets/images/ungvien/template3.png";
import template4 from "../../../assets/images/ungvien/template4.png";
//file image
import forbidden from "../../../assets/images/files/forbidden.webp";
import doc from "../../../assets/images/files/doc.webp";
import pdf from "../../../assets/images/files/pdf.webp";
import xls from "../../../assets/images/files/xls.webp";

import TemplateBasic1 from "../../Profile/MyProfile/CreateCV/TemplateBasic/TemplateBasic1";
import TemplateBasic2 from "../../Profile/MyProfile/CreateCV/TemplateBasic/TemplateBasic2";
import TemplateBasic3 from "../../Profile/MyProfile/CreateCV/TemplateBasic/TemplateBasic3";
import TemplateBasic4 from "../../Profile/MyProfile/CreateCV/TemplateBasic/TemplateBasic4";


const RightSideContent = () => {
  const [activeTab, setActiveTab] = useState("1");

  const tabChange = (tab) => {
    if (activeTab !== tab) setActiveTab(tab);
  };

  const email = getStorage(STORAGE_KEYS.EMAIL);

  const [dropdownOpen, setDropdownOpen] = useState(false);

  const toggle = () => setDropdownOpen((prevState) => !prevState);

  const [modalSelect, setModalSelect] = useState(false);
  const openModalSelect = () => setModalSelect(!modalSelect);

  const [modalPreview, setModalPreview] = useState(false);
  const openModalPreview = () => setModalPreview(!modalPreview);

  const didMount = useRef(false);

  const { t } = useTranslation("translation");

  let date = Moment(new Date).format("YYYY-MM-DD");
  let reader = new FileReader();
  let tmpReader = new FileReader();

  let [avatar, setAvatar] = useState('');
  let [avatarPush, setAvatarPush] = useState('');

  let [fullname, setFullName] = useState('');
  let [otherName, setOtherName] = useState('');
  let [codeArea, setCodeArea] = useState('+84');
  let [phone, setPhone] = useState('');
  let [nationality, setNationality] = useState('');
  let [address, setAddress] = useState('');
  let [permanentAddress, setPermanentAddress] = useState('');

  let [countryId, setCountryId] = useState(1);
  let [cityId, setCityId] = useState(1);
  let [countryList, setCountryList] = useState();
  let [cityList, setCityList] = useState();
  let [cityListAll, setCityListAll] = useState();

  let [facebook, setFacebook] = useState('');
  let [twitter, setTwitter] = useState('');
  let [instagram, setInstagram] = useState('');
  let [whatsapp, setWhatsapp] = useState('');

  let [alphabetName, setAlphabetName] = useState('');
  let [birthDay, setBirthDay] = useState(date);
  let [age, setAge] = useState(0);

  let [gender, setGender] = useState('');
  let [marriage, setMarriage] = useState('');
  let [certificateOfEligibility, setCertificateOfEligibility] = useState('');

  let [visaExpDate, setVisaExpDate] = useState(date);
  let [qualifications, setQualifications] = useState();

  let [japaneseLevel, setJapaneseLevel] = useState();
  let [certificateJapaneseLevelList, setCertificateJapaneseLevelList] = useState();
  let [englishLevel, setEnglishLevel] = useState();
  let [certificateEnglishLevelList, setCertificateEnglishLevelList] = useState();
  let [otherLevel, setOtherLevel] = useState('');

  let [height, setHeight] = useState(0);
  let [weight, setWeight] = useState(0);

  let [bloodType, setBloodType] = useState('');
  let [handedness, setHandedness] = useState('');
  let [eyesight, setEyesight] = useState('');
  let [favoriteSubjects, setFavoriteSubjects] = useState('');

  let [personality, setPersonality] = useState('');
  let [strongPoints, setStrongPoints] = useState('');
  let [weakPoints, setWeakPoints] = useState('');
  let [alertsuccess, setAlertSuccess] = useState(false);
  let [alertdanger, setAlertDanger] = useState(false);
  let [alertvalidate, setAlertValidate] = useState(false);
  let [loading, setLoading] = useState(false);
  let [invalid, setInvalid] = useState(true);

  const [userEducations, setUserEducations] = useState([{
    detail: '', major: '',
    typeSchool: { value: educationLevelList[0].value, label: educationLevelList[0].label },
    enrollmentMonth: date, graduationMonth: date,
    graduateStatus: { value: graduatestatusList[0].value, label: graduatestatusList[0].label },
    enrollmentMonthErr: "", graduationMonthErr: ""
  }]);

  const [userWorkExps, setUserWorkExps] = useState([{
    company: '', jobDesc: '', startMonth: date, endMonth: date,
    position: { value: positionList[6].value, label: positionList[6].label },
    startMonthErr: '', endMonthErr: ''
  }]);

  const [userSkills, setUserSkills] = useState([{ industry: 0, labelIndustry: '', field: 0, labelField: '', skill: 0, labelSkill: '' }]);

  const [visaHistories, setVisaHistories] = useState([{
    country: { value: 0, label: '' }, visaId: { value: visaTypeList[0].value, label: visaTypeList[0].label },
    visaFromDate: date, visaExpDate: date, visaFromDateErr: '', visaExpDateErr: ''
  }]);

  let [visaCountry, setVisaCountry] = useState(0);
  let [visaCountryLabel, setVisaCountryLabel] = useState('');

  let [industry, setIndustry] = useState(0);
  let [labelIndustry, setLabelIndustry] = useState('');
  let [industryList, setIndustryList] = useState();

  let [fields, setFields] = useState(0);
  let [labelField, setLabelField] = useState('');
  let [fieldsList, setFieldsList] = useState();

  let [skill, setSkill] = useState(0);
  let [labelSkill, setLabelSkill] = useState('');
  let [skillList, setSkillList] = useState();

  let [yourself, setYourSelf] = useState('');

  let [fileCV, setFileCV] = useState('');
  let [fileCVType, setFileCVType] = useState('');
  let [fileBTN, setFileBTN] = useState('');
  let [fileBTNType, setFileBTNType] = useState('');
  let [fileBDTN, setFileBDTN] = useState('');
  let [fileBDTNType, setFileBDTNType] = useState('');
  let [fileCCNN, setFileCCNN] = useState('');
  let [fileCCNNType, setFileCCNNType] = useState('');
  let [fileHC, setFileHC] = useState('');
  let [fileHCType, setFileHCType] = useState('');
  let [fileTCT, setFileTCT] = useState('');
  let [fileTCTType, setFileTCTType] = useState('');
  let [fileBLX, setFileBLX] = useState('');
  let [fileBLXType, setFileBLXType] = useState('');
  let [fileCCTA, setFileCCTA] = useState('');
  let [fileCCTAType, setFileCCTAType] = useState('');
  let [fileCCTN, setFileCCTN] = useState('');
  let [fileCCTNType, setFileCCTNType] = useState('');
  let [fileCCTH, setFileCCTH] = useState('');
  let [fileCCTHType, setFileCCTHType] = useState('');

  let [fileCVPath, setFileCVPath] = useState('');
  let [fileBTNPath, setFileBTNPath] = useState('');
  let [fileBDTNPath, setFileBDTNPath] = useState('');
  let [fileCCNNPath, setFileCCNNPath] = useState('');
  let [fileHCPath, setFileHCPath] = useState('');
  let [fileTCTPath, setFileTCTPath] = useState('');
  let [fileBLXPath, setFileBLXPath] = useState('');
  let [fileCCTAPath, setFileCCTAPath] = useState('');
  let [fileCCTNPath, setFileCCTNPath] = useState('');
  let [fileCCTHPath, setFileCCTHPath] = useState('');

  let [srcCV, setSrcCV] = useState('');
  let [fileNameCV, setFileNameCV] = useState('');
  let [fileImgCV, setFileImgCV] = useState([]);

  let [srcBTN, setSrcBTN] = useState('');
  let [fileNameBTN, setFileNameBTN] = useState('');
  let [fileImgBTN, setFileImgBTN] = useState([]);

  let [srcBDTN, setSrcBDTN] = useState('');
  let [fileNameBDTN, setFileNameBDTN] = useState('');
  let [fileImgBDTN, setFileImgBDTN] = useState([]);

  let [srcCCNN, setSrcCCNN] = useState('');
  let [fileNameCCNN, setFileNameCCNN] = useState('');
  let [fileImgCCNN, setFileImgCCNN] = useState([]);

  let [srcHC, setSrcHC] = useState('');
  let [fileNameHC, setFileNameHC] = useState('');
  let [fileImgHC, setFileImgHC] = useState([]);

  let [srcTCT, setSrcTCT] = useState('');
  let [fileNameTCT, setFileNameTCT] = useState('');
  let [fileImgTCT, setFileImgTCT] = useState([]);

  let [srcBLX, setSrcBLX] = useState('');
  let [fileNameBLX, setFileNameBLX] = useState('');
  let [fileImgBLX, setFileImgBLX] = useState([]);

  let [srcCCTA, setSrcCCTA] = useState('');
  let [fileNameCCTA, setFileNameCCTA] = useState('');
  let [fileImgCCTA, setFileImgCCTA] = useState([]);

  let [srcCCTN, setSrcCCTN] = useState('');
  let [fileNameCCTN, setFileNameCCTN] = useState('');
  let [fileImgCCTN, setFileImgCCTN] = useState([]);

  let [srcCCTH, setSrcCCTH] = useState('');
  let [fileNameCCTH, setFileNameCCTH] = useState('');
  let [fileImgCCTH, setFileImgCCTH] = useState([]);

  let [dataPdf, setDataPdf] = useState();
  let [filenamePdf, setFileNamePdf] = useState("Template");
  let [templateId, setTemplateId] = useState(0);

  useEffect(() => {
    getMyFile().then((res) => {
      res.map((item, key) => {
        if (item.fileTypeId == 1) {
          setFileCVType(item.ext);
          setFileCVPath(item.path);
        } if (item.fileTypeId == 2) {
          setFileBTNType(item.ext);
          setFileBTNPath(item.path);
        } if (item.fileTypeId == 3) {
          setFileBDTNType(item.ext);
          setFileBDTNPath(item.path);
        } if (item.fileTypeId == 4) {
          setFileCCNNType(item.ext);
          setFileCCNNPath(item.path);
        } if (item.fileTypeId == 5) {
          setFileHCType(item.ext);
          setFileHCPath(item.path);
        } if (item.fileTypeId == 6) {
          setFileTCTType(item.ext);
          setFileTCTPath(item.path);
        } if (item.fileTypeId == 7) {
          setFileBLXType(item.ext);
          setFileBLXPath(item.path);
        } if (item.fileTypeId == 8) {
          setFileCCTAType(item.ext);
          setFileCCTAPath(item.path);
        } if (item.fileTypeId == 9) {
          setFileCCTNType(item.ext);
          setFileCCTNPath(item.path);
        } if (item.fileTypeId == 10) {
          setFileCCTHType(item.ext);
          setFileCCTHPath(item.path);
        }
      })
    });
    let fileImage = {
      doc: doc,
      pdf: pdf,
      xls: xls,
      forbidden: forbidden
    }
    setFileImgCV(fileImage);
    setFileImgBTN(fileImage);
    setFileImgBDTN(fileImage);
    setFileImgCCNN(fileImage);
    setFileImgHC(fileImage);
    setFileImgTCT(fileImage);
    setFileImgBLX(fileImage);
    setFileImgCCTA(fileImage);
    setFileImgCCTN(fileImage);
    setFileImgCCTH(fileImage);
    getAccountInfo().then((res) => {
      if (res) {
        res.avatar ? setAvatar(res.avatar) : setAvatar('');
        res.fullName ? setFullName(res.fullName) : setFullName('');
        res.otherName ? setOtherName(res.otherName) : setOtherName('');
        if (res.phone) {
          let phoneData = res.phone.split('|');
          phoneData[0] ? setCodeArea(phoneData[0]) : setCodeArea('+84');
          phoneData[1] ? setPhone(phoneData[1]) : setPhone('');
        }

        if (res.website) {
          let websiteData = res.website.split('|');
          websiteData[0] ? setFacebook(websiteData[0]) : setFacebook("");
          websiteData[1] ? setInstagram(websiteData[1]) : setInstagram("");
          websiteData[2] ? setTwitter(websiteData[2]) : setTwitter("");
          websiteData[3] ? setWhatsapp(websiteData[3]) : setWhatsapp("");
        }
        res.about ? setYourSelf(res.about) : setYourSelf('');
        res.address ? setAddress(res.address) : setAddress('');
        res.infoData.alphabetName ? setAlphabetName(res.infoData.alphabetName) : setAlphabetName('');
        res.infoData.permanentAddress ? setPermanentAddress(res.infoData.permanentAddress) : setPermanentAddress('');
        res.infoData.birthDay ? setBirthDay(res.infoData.birthDay) : setBirthDay(date);
        if (res.infoData.birthDay) {
          let birthDate = new Date(res.infoData.birthDay);
          let difference = Date.now() - birthDate.getTime();
          let ageDate = new Date(difference);
          let age = Math.abs(ageDate.getUTCFullYear() - 1970);
          setAge(age);
        }
        res.infoData.gender ? setGender(res.infoData.gender) : setGender(1);
        res.infoData.marriage ? setMarriage(res.infoData.marriage) : setMarriage(1);

        if (res.infoData.certificateOfEligibility) {
          const certificateOfEligibilityFilter = certificateOfEligibilityList.filter(c => c.value == res.infoData.certificateOfEligibility);
          certificateOfEligibilityFilter ? setCertificateOfEligibility(certificateOfEligibilityFilter[0]) : setCertificateOfEligibility(certificateOfEligibilityList[0]);
        } else {
          setCertificateOfEligibility(certificateOfEligibilityList[0]);
        }

        res.infoData.visaExpDate ? setVisaExpDate(res.infoData.visaExpDate) : setVisaExpDate(date);
        if (res.infoData.qualifications) {
          const qualificationFilter = qualificationList.filter(q => q.value == res.infoData.qualifications);
          qualificationFilter ? setQualifications(qualificationFilter[0]) : setQualifications(qualificationList[0]);
        } else {
          setQualifications(qualificationList[0]);
        }

        res.infoData.otherLevel ? setOtherLevel(res.infoData.otherLevel) : setOtherLevel('');
        res.infoData.height ? setHeight(res.infoData.height) : setHeight(0);
        res.infoData.weight ? setWeight(res.infoData.weight) : setWeight(0);

        if (res.infoData.bloodType) {
          const bloodTypeFilter = bloodTypeList.filter(q => q.value == res.infoData.bloodType);
          bloodTypeFilter ? setBloodType(bloodTypeFilter[0]) : setBloodType(bloodTypeList[0]);
        } else {
          setBloodType(bloodTypeList[0]);
        }

        if (res.infoData.handedness) {
          const handednessFilter = preferredHandList.filter(h => h.value == res.infoData.handedness);
          handednessFilter ? setHandedness(handednessFilter[0]) : setHandedness(preferredHandList[0]);
        } else {
          setHandedness(preferredHandList[0]);
        }

        if (res.infoData.eyesight) {
          const eyeFilter = eyesightList.filter(q => q.value == res.infoData.eyesight);
          eyeFilter ? setEyesight(eyeFilter[0]) : setEyesight(eyesightList[0]);
        } else {
          setEyesight(eyesightList[0]);
        }

        if (res.infoData.favoriteSubjects) {
          const favoriteSubjectsFilter = favoriteSubjectList.filter(f => f.value == res.infoData.favoriteSubjects);
          favoriteSubjectsFilter ? setFavoriteSubjects(favoriteSubjectsFilter[0]) : setFavoriteSubjects(favoriteSubjectList[0]);
        } else {
          setFavoriteSubjects(favoriteSubjectList[0]);
        }

        if (res.infoData.personality) {
          const personalityFilter = personalityList.filter(f => f.value == res.infoData.personality);
          personalityFilter ? setPersonality(personalityFilter[0]) : setPersonality(personalityList[0]);
        } else {
          setPersonality(personalityList[0]);
        }

        if (res.infoData.strongPoints) {
          const strongPointsFilter = strongPointList.filter(f => f.value == res.infoData.strongPoints);
          strongPointsFilter ? setStrongPoints(strongPointsFilter[0]) : setStrongPoints(strongPointList[0]);
        } else {
          setStrongPoints(strongPointList[0]);
        }

        if (res.infoData.weakPoints) {
          const weakPointsFilter = weakpointList.filter(f => f.value == res.infoData.weakPoints);
          weakPointsFilter ? setWeakPoints(weakPointsFilter[0]) : setWeakPoints(weakpointList[0]);
        } else {
          setWeakPoints(weakpointList[0]);
        }

        if (res.infoData.userEducations && res.infoData.userEducations.length > 0) {
          let userEducationRes = [];
          res.infoData.userEducations.map((item, key) => {
            let graduateStatusData = graduatestatusList.filter(g => g.value == item.graduateStatus);
            let typeSchoolData = educationLevelList.filter(t => t.value == item.typeSchool);
            userEducationRes.push({
              detail: item.detail, major: item.major,
              typeSchool: { value: typeSchoolData[0].value, label: typeSchoolData[0].label },
              enrollmentMonth: item.enrollmentMonth, graduationMonth: item.graduationMonth,
              graduateStatus: { value: graduateStatusData[0].value, label: graduateStatusData[0].label },
              enrollmentMonthErr: "", graduationMonthErr: ""
            })
          })
          setUserEducations(userEducationRes);
        } else {
          setUserEducations([{
            detail: '', major: '',
            typeSchool: { value: educationLevelList[0].value, label: educationLevelList[0].label },
            enrollmentMonth: date, graduationMonth: date,
            graduateStatus: { value: graduatestatusList[0].value, label: graduatestatusList[0].label },
            enrollmentMonthErr: "", graduationMonthErr: ""
          }]);
        }

        if (res.infoData.userWorkExps && res.infoData.userWorkExps.length > 0) {
          let userWorkRes = [];
          res.infoData.userWorkExps.map((item, key) => {
            let positionData = positionList.filter(p => p.value == item.position);
            userWorkRes.push({
              company: item.company, jobDesc: item.jobDesc, startMonth: item.startMonth, endMonth: item.endMonth,
              position: { value: positionData[0].value, label: positionData[0].label },
              startMonthErr: '', endMonthErr: ''
            });
          });
          setUserWorkExps(userWorkRes);
        } else {
          setUserWorkExps([{
            company: '', jobDesc: '', startMonth: date, endMonth: date,
            position: { value: positionList[6].value, label: positionList[6].label },
            startMonthErr: '', endMonthErr: ''
          }]);
        }

        //Get data master
        GetCountryList().then((result) => {
          if (result.country) {
            const countryArr = [];
            const cityArr = [];
            const cityListAllArr = [];
            const visaHistorieArr = [];
            result.country.map((item, key) => {
              //Get and Set Data
              if (res.infoData.nationality) {
                if (item.id == res.infoData.nationality) {
                  setNationality({ value: item.id, label: item.name });
                }
              }
              if (res.cityId && res.infoData.countryId) {
                let cities = item.cities.filter(c => c.countryID == res.infoData.countryId);
                if (cities.length > 0) {
                  cities.map((itemcity, keycity) => {
                    if (res.cityId) {
                      if (res.cityId == itemcity.id) {
                        setCountryId({ value: item.id, label: item.name });
                        setCityId({ value: itemcity.id, label: itemcity.name });
                      }
                    }
                    cityArr.push({ value: itemcity.id, label: itemcity.name });
                  })
                  setCityList(cityArr);
                }
              }
              if (res.infoData.visaHistories != undefined && res.infoData.visaHistories.length > 0 && res.infoData.certificateOfEligibility == 2) {
                res.infoData.visaHistories.map((visa, keyvisa) => {
                  if (item.id == visa.counntryID) {
                    let visaData = visaTypeList.filter(v => v.value == visa.visaId);
                    visaHistorieArr.push({
                      country: { value: visa.counntryID, label: item.name }, visaId: { value: visaData[0].value, label: visaData[0].label },
                      visaFromDate: visa.fromDate, visaExpDate: visa.toDate, visaFromDateErr: '', visaExpDateErr: ''
                    });
                  }
                })
                setVisaHistories(visaHistorieArr)
              }
              //Init data
              if (key === 0) {
                if (!res.infoData.nationality) {
                  setNationality({ value: item.id, label: item.name });
                }
                setVisaCountry(item.id);
                setVisaCountryLabel(item.name);
                if (res.infoData.visaHistories == undefined || res.infoData.visaHistories.length == 0) {
                  setVisaHistories([{
                    country: { value: item.id, label: item.name }, visaId: { value: visaTypeList[0].value, label: visaTypeList[0].label },
                    visaFromDate: date, visaExpDate: date, visaFromDateErr: '', visaExpDateErr: ''
                  }]);
                }
                if (!res.cityId && !res.infoData.countryId) {
                  let cities = item.cities.filter(c => c.countryID === item.id);
                  if (cities.length > 0) {
                    cities.map((itemcity, keycity) => {
                      if (keycity === 0) {
                        setCountryId({ value: item.id, label: item.name });
                        setCityId({ value: itemcity.id, label: itemcity.name });
                      }
                      cityArr.push({ value: itemcity.id, label: itemcity.name });
                    })
                    setCityList(cityArr);
                  }
                }
              }
              countryArr.push({ value: item.id, label: item.name });
              cityListAllArr.push(item.cities);
            });
            setCountryList(countryArr);
            setCityListAll(cityListAllArr);
          }
          if (result.language) {
            const japanFilter = result.language.filter(l => l.levelCode === "JP");
            let japanArr = [{ value: '0', label: 'Không yêu cầu' }];
            let japaneseData = japanFilter.filter(j => j.id == res.infoData.japaneseLevel);
            if (japaneseData.length > 0) {
              setJapaneseLevel({ value: japaneseData[0].id, label: japaneseData[0].levelName });
            } else {
              setJapaneseLevel(japanArr[0]);
            }
            japanFilter.map((item, key) => {
              japanArr.push({ value: item.id, label: item.levelName });
            });
            setCertificateJapaneseLevelList(japanArr);

            const englishFilter = result.language.filter(l => l.levelCode === "EN");
            let englishArr = [{ value: '0', label: 'Không yêu cầu' }];
            let englishData = englishFilter.filter(l => l.id == res.infoData.englishLevel);
            if (englishData.length > 0) {
              setEnglishLevel({ value: englishData[0].id, label: englishData[0].levelName });
            } else {
              setEnglishLevel(englishArr[0]);
            }
            englishFilter.map((item, key) => {
              englishArr.push({ value: item.id, label: item.levelName });
            });
            setCertificateEnglishLevelList(englishArr);
          }
          if (result.category) {
            if (res.infoData.skills && res.infoData.skills.length > 0) {
              let userSkillArr = [];
              result.category.map((item, key) => {
                item.children.map((item2, key2) => {
                  item2.children.map((item3, key3) => {
                    res.infoData.skills.map((skill, keyskill) => {
                      if (item3.id == skill.categoryId) {
                        userSkillArr.push({
                          industry: item.id, labelIndustry: item.nameVi, field: item2.id,
                          labelField: item2.nameVi, skill: item3.id, labelSkill: item3.nameVi
                        });
                      }
                    })
                  })
                })
              });
              setUserSkills(userSkillArr);

              let industryInit, fieldInit, skillInit = 0;
              let labelIndustryInit, labelFieldInit, labelSkillInit = '';
              let industryArr = [];
              result.category.map((item, key) => {
                if (key === 0) {
                  industryInit = item.id;
                  labelIndustryInit = item.nameVi;
                }
                industryArr.push({ value: item.id, label: item.nameVi, children: item.children });
              });
              setIndustryList(industryArr);

              const fieldFilter = result.category.filter(i => i.id === industryInit);
              let fieldArr = [];
              fieldFilter[0].children.map((item, key) => {
                if (key === 0) {
                  fieldInit = item.id;
                  labelFieldInit = item.nameVi;
                }
                fieldArr.push({ value: item.id, label: item.nameVi, children: item.children });
              });
              setFieldsList(fieldArr);

              const skillFilter = fieldFilter[0].children.filter(i => i.id === fieldInit);
              let skillArr = [];
              skillFilter[0].children.map((item, key) => {
                if (key === 0) {
                  skillInit = item.id;
                  labelSkillInit = item.nameVi;
                }
                skillArr.push({ value: item.id, label: item.nameVi });
              });
              setSkillList(skillArr);
              setIndustry(industryInit);
              setLabelIndustry(labelIndustryInit);

              setFields(fieldInit);
              setLabelField(labelFieldInit);

              setSkill(skillInit);
              setLabelSkill(labelSkillInit);
            } else {
              let industryInit, fieldInit, skillInit = 0;
              let labelIndustryInit, labelFieldInit, labelSkillInit = '';
              let industryArr = [];
              result.category.map((item, key) => {
                if (key === 0) {
                  industryInit = item.id;
                  labelIndustryInit = item.nameVi;
                }
                industryArr.push({ value: item.id, label: item.nameVi, children: item.children });
              });
              setIndustryList(industryArr);

              const fieldFilter = result.category.filter(i => i.id === industryInit);
              let fieldArr = [];
              fieldFilter[0].children.map((item, key) => {
                if (key === 0) {
                  fieldInit = item.id;
                  labelFieldInit = item.nameVi;
                }
                fieldArr.push({ value: item.id, label: item.nameVi, children: item.children });
              });
              setFieldsList(fieldArr);

              const skillFilter = fieldFilter[0].children.filter(i => i.id === fieldInit);
              let skillArr = [];
              skillFilter[0].children.map((item, key) => {
                if (key === 0) {
                  skillInit = item.id;
                  labelSkillInit = item.nameVi;
                }
                skillArr.push({ value: item.id, label: item.nameVi });
              });
              setSkillList(skillArr);

              setIndustry(industryInit);
              setLabelIndustry(labelIndustryInit);

              setFields(fieldInit);
              setLabelField(labelFieldInit);

              setSkill(skillInit);
              setLabelSkill(labelSkillInit);

              setUserSkills([{
                industry: industryInit, labelIndustry: labelIndustryInit, field: fieldInit, labelField: labelFieldInit,
                skill: skillInit, labelSkill: labelSkillInit
              }])
            }
          }
        });
      }
    });
  }, []);

  const downloadFileImported = (path, filetype, filename) => {
    downloadFile(path).then((result) => {
      const url = window.URL.createObjectURL(new Blob([result]));
      const link = document.createElement("a");
      link.href = url;
      link.download = filename + "_" + Moment(new Date).format("YYYYMMDDHHmmss") + filetype;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      window.URL.revokeObjectURL(url);
    })
  }
  //check validate
  let [fullnameErr, setFullNameErr] = useState('');
  let [phoneErr, setPhoneErr] = useState('');


  const handleUploadAvatar = event => {
    const avatarLoaded = URL.createObjectURL(event.target.files[0]);
    updateAvatar(event.target.files[0]).then((result) => {
    });
    setAvatar(avatarLoaded);
  }

  const handleOnClickFacebook = event => {
    window.open(facebook);
  }

  const handleOnClickInstagram = event => {
    window.open(instagram);
  }

  const handleOnClickTwitter = event => {
    window.open(twitter);
  }

  const handleOnClickWhatsApp = event => {
    window.open(whatsapp);
  }

  const handleCertificateOfEligibility = event => {
    setCertificateOfEligibility({ value: event.value, label: event.label });
  }

  const handleQualifications = event => {
    setQualifications({ value: event.value, label: event.label });
  }

  const handleJapaneseLevel = event => {
    setJapaneseLevel({ value: event.value, label: event.label });
  }

  const handleEnglishLevel = event => {
    setEnglishLevel({ value: event.value, label: event.label });
  }

  const handleOtherLevel = event => {
    setOtherLevel(event.target.value);
  }

  const handleHeight = event => {
    setHeight(event.target.value);
  }

  const handleWeight = event => {
    setWeight(event.target.value);
  }

  const handleBloodType = event => {
    setBloodType({ value: event.value, label: event.label });
  }

  const handleHandedness = event => {
    setHandedness({ value: event.value, label: event.label });
  }

  const handleEyesight = event => {
    setEyesight({ value: event.value, label: event.label });
  }

  const handleFavoriteSubjects = event => {
    setFavoriteSubjects({ value: event.value, label: event.label });
  }

  const handlePersonality = event => {
    setPersonality({ value: event.value, label: event.label });
  }

  const handleStrongPoint = event => {
    setStrongPoints({ value: event.value, label: event.label });
  }

  const handleWeakPoints = event => {
    setWeakPoints({ value: event.value, label: event.label });
  }

  const handleYourSelf = event => {
    setYourSelf(event.target.value);
  }

  const handleVisaHistories = (event, index, name) => {
    let onChangeValue = [...visaHistories];
    if (name == 'country') {
      onChangeValue[index][name].value = event.value;
      onChangeValue[index][name].label = event.label;
    }
    if (name == 'visaId') {
      onChangeValue[index][name].value = event.value;
      onChangeValue[index][name].label = event.label;
    }
    if (name == 'visaFromDate') {
      onChangeValue[index][name] = event.target.value;
      if (Moment(event.target.value).format("YYYYMMDD") > Moment(onChangeValue[index]["visaExpDate"]).format("YYYYMMDD")) {
        onChangeValue[index]["visaFromDateErr"] = "Không được lớn hơn ngày hết hạn";
        onChangeValue[index]["visaExpDateErr"] = "";
      } else {
        onChangeValue[index]["visaFromDateErr"] = "";
      }
    }
    if (name == 'visaExpDate') {
      onChangeValue[index][name] = event.target.value;
      if (Moment(event.target.value).format("YYYYMMDD") < Moment(onChangeValue[index]["visaFromDate"]).format("YYYYMMDD")) {
        onChangeValue[index]["visaExpDateErr"] = "Không được nhỏ hơn ngày bắt đầu";
        onChangeValue[index]["visaFromDateErr"] = "";
      } else {
        onChangeValue[index]["visaExpDateErr"] = "";
      }
    }
    setVisaHistories(onChangeValue);
    checkInvalid_Date();
  };

  const handleAddVisaHistories = () => {
    setVisaHistories([...visaHistories, {
      country: { value: visaCountry, label: visaCountryLabel },
      visaId: { value: visaTypeList[0].value, label: visaTypeList[0].label }, visaFromDate: date, visaExpDate: date,
      visaFromDateErr: '', visaExpDateErr: ''
    }]);
  };

  const handleDeleteVisaHistories = (index) => {
    const newArray = [...visaHistories];
    newArray.splice(index, 1);
    setVisaHistories(newArray);
  };

  const handleUserEducations = (event, index, name) => {
    let onChangeValue = [...userEducations];
    if (name == 'enrollmentMonth') {
      onChangeValue[index][name] = event.target.value;
      if (Moment(event.target.value).format("YYYYMMDD") > Moment(onChangeValue[index]["graduationMonth"]).format("YYYYMMDD")) {
        onChangeValue[index]["enrollmentMonthErr"] = "Không được lớn hơn ngày tốt nghiệp";
        onChangeValue[index]["graduationMonthErr"] = "";
      } else {
        onChangeValue[index]["enrollmentMonthErr"] = "";
      }
    }
    if (name == 'graduationMonth') {
      onChangeValue[index][name] = event.target.value;
      if (Moment(event.target.value).format("YYYYMMDD") < Moment(onChangeValue[index]["enrollmentMonth"]).format("YYYYMMDD")) {
        onChangeValue[index]["graduationMonthErr"] = "Không được nhỏ hơn ngày nhập học";
        onChangeValue[index]["enrollmentMonthErr"] = "";
      } else {
        onChangeValue[index]["graduationMonthErr"] = "";
      }
    }
    if (name == 'detail') {
      onChangeValue[index][name] = event.target.value;
    }
    if (name == 'major') {
      onChangeValue[index][name] = event.target.value;
    }
    if (name == 'typeSchool') {
      onChangeValue[index][name].value = event.value;
      onChangeValue[index][name].label = event.label;
    }
    if (name == 'graduateStatus') {
      onChangeValue[index][name].value = event.value;
      onChangeValue[index][name].label = event.label;
    }
    setUserEducations(onChangeValue);
    checkInvalid_Date();
  };

  const handleAddUserEducations = () => {
    setUserEducations([...userEducations, {
      detail: '', major: '',
      typeSchool: { value: educationLevelList[0].value, label: educationLevelList[0].label },
      enrollmentMonth: date, graduationMonth: date,
      graduateStatus: { value: graduatestatusList[0].value, label: graduatestatusList[0].label },
      enrollmentMonthErr: "", graduationMonthErr: ""
    }]);
  };

  const handleDeleteUserEducations = (index) => {
    const newArray = [...userEducations];
    newArray.splice(index, 1);
    setUserEducations(newArray);
  };

  const handleUserWorkExps = (event, index, name) => {
    let onChangeValue = [...userWorkExps];
    if (name == 'startMonth') {
      onChangeValue[index][name] = event.target.value;
      if (Moment(event.target.value).format("YYYYMMDD") > Moment(onChangeValue[index]["endMonth"]).format("YYYYMMDD")) {
        onChangeValue[index]["startMonthErr"] = "Không được lớn hơn ngày nghỉ việc";
        onChangeValue[index]["endMonthErr"] = "";
      } else {
        onChangeValue[index]["startMonthErr"] = "";
      }
    }
    if (name == 'endMonth') {
      onChangeValue[index][name] = event.target.value;
      if (Moment(event.target.value).format("YYYYMMDD") < Moment(onChangeValue[index]["startMonth"]).format("YYYYMMDD")) {
        onChangeValue[index]["endMonthErr"] = "Không được nhỏ hơn ngày vào làm";
        onChangeValue[index]["startMonthErr"] = "";
      } else {
        onChangeValue[index]["endMonthErr"] = "";
      }
    }
    if (name == 'company') {
      onChangeValue[index][name] = event.target.value;
    }
    if (name == 'position') {
      onChangeValue[index][name].value = event.value;
      onChangeValue[index][name].label = event.label;
    }
    if (name == 'jobDesc') {
      onChangeValue[index][name] = event.target.value;
    }
    setUserWorkExps(onChangeValue);
    checkInvalid_Date();
  };

  const handleAddUserWorkExps = () => {
    setUserWorkExps([...userWorkExps, {
      company: '', jobDesc: '', startMonth: date, endMonth: date,
      position: { value: positionList[6].value, label: positionList[6].label },
      startMonthErr: '', endMonthErr: ''
    }]);
  };

  const handleDeleteUserWorkExps = (index) => {
    const newArray = [...userWorkExps];
    newArray.splice(index, 1);
    setUserWorkExps(newArray);
  };

  const handleAddUserSkills = () => {
    setUserSkills([...userSkills, {
      industry: industry, labelIndustry: labelIndustry, field: fields, labelField: labelField,
      skill: skill, labelSkill: labelSkill
    }]);
  };

  const handleDeleteUserSkills = (index) => {
    const newArray = [...userSkills];
    newArray.splice(index, 1);
    setUserSkills(newArray);
  };

  const handleUserSkills = (event, index, name) => {
    if (event.value) {
      let onChangeValue = [...userSkills];
      onChangeValue[index][name] = event.value;
      if (name == 'industry') {
        onChangeValue[index]['labelIndustry'] = event.label;
        let fieldArr = [];
        event.children && event.children.map((item, key) => {
          if (key == 0) {
            onChangeValue[index]['fields'] = item.id;
            onChangeValue[index]['labelField'] = item.nameVi;

            onChangeValue[index]['skill'] = 0;
            onChangeValue[index]['labelSkill'] = '';
          }
          fieldArr.push({ value: item.id, label: item.nameVi, children: item.children });
        });
        setFieldsList(fieldArr);
        setSkillList();
      }
      if (name == 'fields') {
        onChangeValue[index]['labelField'] = event.label;
        let skillArr = [];
        event.children && event.children.map((item, key) => {
          if (key == 0) {
            onChangeValue[index]['skill'] = item.id;
            onChangeValue[index]['labelSkill'] = item.nameVi;
          }
          skillArr.push({ value: item.id, label: item.nameVi });
        });
        setSkillList(skillArr);
      };
      if (name == 'skill') {
        onChangeValue[index]['labelSkill'] = event.label;
        let skillArr = [];
        event.children && event.children.map((item, key) => {
          skillArr.push({ value: item.id, label: item.nameVi });
        });
      };
      setUserSkills(onChangeValue);
    };
  };

  const background_color = '#ffffff';

  useEffect(() => {
    if (!didMount.current) {
      return () => {
        didMount.current = true;
      };
    }
    checkValidate('fullname');
  }, [fullname]);

  useEffect(() => {
    if (!didMount.current) {
      return () => {
        didMount.current = true;
      };
    }
    checkValidate('phone');
  }, [phone]);

  const checkValidate = (value) => {
    let checkFullName = true;
    let checkPhone = true;
    if (value === 'fullname') {
      if (!fullname) {
        setFullNameErr('Vui lòng nhập họ tên!');
        checkFullName = true;
      } else {
        setFullNameErr('');
        checkFullName = false;
      }
      (!checkPhoneNumberFormat(phone)) ? checkPhone = true : checkPhone = false;
    }
    if (value === 'phone') {
      if ((!phone && !checkPhoneNumberFormat(phone, phones["vi-VN"])) || phone.length > 9 || phone.length < 9) {
        setPhoneErr('Định dạng SĐT chưa chính xác!');
        checkPhone = true;
      } else {
        setPhoneErr('');
        checkPhone = false;
      }
      !fullname ? checkFullName = true : checkFullName = false;
    }
    (checkFullName || checkPhone) ? setInvalid(true) : setInvalid(false);
  }

  const checkInvalid_Date = () => {
    let userEducationsErr = userEducations.filter(u => u.enrollmentMonthErr != "" || u.graduationMonthErr != "");
    let userWorkExpsErr = userWorkExps.filter(u => u.startMonthErr != "" || u.endMonthErr != "");
    let visaHistoriesErr = visaHistories.filter(v => v.visaFromDateErr != "" || v.visaExpDateErr != "");
    if (userEducationsErr.length > 0 || userWorkExpsErr.length > 0 || visaHistoriesErr.length > 0 || fullnameErr || phoneErr) {
      setInvalid(true);
    } else {
      setInvalid(false);
    }
  }

  const handleUpdateProfile = () => {
    const userEducationData = userEducations.filter(u => u.detail == '');
    const userWorkExpData = userWorkExps.filter(u => u.company == '');
    if ((userEducationData.length > 0)) {
      setAlertValidate(true);
      return;
    } else {
      setAlertValidate(false);
    }
    let educationData = [];
    userEducationData.length == 0 && userEducations.map((item, key) => {
      educationData.push({
        detail: item.detail, major: item.major, typeSchool: item.typeSchool.value.toString(),
        enrollmentMonth: item.enrollmentMonth, graduationMonth: item.graduationMonth,
        typeSchool: Number(item.typeSchool.value), graduateStatus: Number(item.graduateStatus.value)
      });
    });

    let workExpData = [];
    userWorkExpData.length == 0 && userWorkExps.map((item, key) => {
      workExpData.push({
        company: item.company, jobDesc: item.jobDesc, startMonth: item.startMonth, endMonth: item.endMonth, position: item.position.value.toString()
      });
    });

    let skillData = [];
    userSkills.length > 0 && userSkills.map((item, key) => {
      let data = {
        categoryId: Number(item.skill),
        category: {
          id: Number(item.skill),
          nameVi: item.labelSkill,
          nameEn: null,
          nameDe: null,
          nameJp: null,
          nameKr: null,
          parent: null,
          children: null
        }
      }
      skillData.push(data);
    });

    let visaHistoriesData = [];
    if (visaHistories.length > 0 && certificateOfEligibility.value == 2) {
      visaHistories.map((item, key) => {
        visaHistoriesData.push({
          counntryID: item.country.value, visaId: Number(item.visaId.value),
          fromDate: item.visaFromDate, toDate: item.visaExpDate
        });
      })
    }

    let employeeInfo = {
      alphabetName: alphabetName,
      permanentAddress: permanentAddress,
      birthDay: birthDay,
      gender: Number(gender),
      marriage: Number(marriage),
      certificateOfEligibility: certificateOfEligibility.value.toString(),
      nationality: nationality.value,
      countryId: countryId.value,
      visaExpDate: visaExpDate,
      qualifications: qualifications.value.toString(),
      japaneseLevel: japaneseLevel.value.toString(),
      englishLevel: englishLevel.value.toString(),
      otherLevel: otherLevel,
      height: Number(height),
      weight: Number(weight),
      bloodType: Number(bloodType.value),
      handedness: Number(handedness.value),
      eyesight: eyesight.value,
      favoriteSubjects: favoriteSubjects.value.toString(),
      personality: Number(personality.value),
      strongPoints: Number(strongPoints.value),
      weakPoints: Number(weakPoints.value),
      graduateStatus: 0,
      userEducations: (userEducationData.length > 0 && userEducations.length == 1) ? [] : educationData,
      userWorkExps: (userWorkExpData.length > 0 && userWorkExps.length == 1) ? [] : workExpData,
      skills: skillData,
      visaHistories: visaHistoriesData
    };

    let website = facebook + '|' + instagram + '|' + twitter + '|' + whatsapp;
    setAlertDanger(false);
    setAlertSuccess(false);
    setLoading(true);
    updateProfile(fullname, otherName, codeArea + '|' + phone, address, cityId.value, website, yourself, employeeInfo).then((res) => {
      if (res.status === 200) {
        if (avatarPush) {
          updateAvatar(avatarPush).then((result) => {
            if (result.status === 200) {
              setLoading(false);
              setAlertSuccess(true);
              setAlertDanger(false);
            }
          });
        } else {
          setLoading(false);
          setAlertSuccess(true);
          setAlertDanger(false);
        }
      } else {
        setLoading(false);
        setAlertDanger(true);
        setAlertSuccess(false);
      }
    })
  };

  const handleUploadFile = (event, filetype) => {
    const file = event.target.files[0];
    readFile(file, filetype);
    const size = file.size;
    if ((size / 1024) <= 10024) {
      updateFile(event.target.files[0], filetype).then((result) => {
        if (result.status == 200) {
          (filetype == 1) ? setFileCV('Upload file CV thành công') : setFileCV('');
          (filetype == 2) ? setFileBTN('Upload bằng tốt nghiệp thành công') : setFileBTN('');
          (filetype == 3) ? setFileBDTN('Upload bảng điểm thành công') : setFileBDTN('');
          (filetype == 4) ? setFileCCNN('Upload chứng chỉ ngoại ngữ thành công') : setFileCCNN('');
          (filetype == 5) ? setFileHC('Upload hộ chiếu thành công') : setFileHC('');
          (filetype == 6) ? setFileTCT('Upload visa hoặc thẻ cư trú thành công') : setFileTCT('');
          (filetype == 7) ? setFileBLX('Upload bằng lái xe thành công') : setFileBLX('');
          (filetype == 8) ? setFileCCTA('Upload chứng chỉ tiếng Anh thành công') : setFileCCTA('');
          (filetype == 9) ? setFileCCTN('Upload chứng chỉ tiếng Nhật thành công') : setFileCCTN('');
          (filetype == 10) ? setFileCCTH('Upload chứng chỉ tiếng Hàn thành công') : setFileCCTH('');
        }
      });
    } else {
      (filetype == 1) ? setFileCV('File CV phải < 10MB') : setFileCV('');
      (filetype == 2) ? setFileBTN('File bằng tốt nghiệp phải < 10MB') : setFileBTN('');
      (filetype == 3) ? setFileBDTN('File bảng điểm phải < 10MB') : setFileBDTN('');
      (filetype == 4) ? setFileCCNN('File chứng chỉ ngoại ngữ phải < 10MB') : setFileCCNN('');
      (filetype == 5) ? setFileHC('File hộ chiếu phải < 10MB') : setFileHC('');
      (filetype == 6) ? setFileTCT('File visa hoặc thẻ cư trú phải < 10MB') : setFileTCT('');
      (filetype == 7) ? setFileBLX('File bằng lái xe phải < 10MB') : setFileBLX('');
      (filetype == 8) ? setFileCCTA('File chứng chỉ tiếng Anh phải < 10MB') : setFileCCTA('');
      (filetype == 9) ? setFileCCTN('File chứng chỉ tiếng Nhật phải < 10MB') : setFileCCTN('');
      (filetype == 10) ? setFileCCTH('File chứng chỉ tiếng Hàn phải < 10MB') : setFileCCTH('');
    }
  }

  const validateBinary = (result) => {
    const uint = new Uint8Array(result);
    const hex = [...uint].map((byte) => byte.toString(16)).join('').toUpperCase();
    const ACCEPT_BINARY = ['89504E47', 'FFD8FFE0', 'FFD8FFDB', 'FFD8FFE1', 'FFD8FFE8', 'FFD8FFE3', 'FFD8FFE2', '504B34', '25504446'];

    return (ACCEPT_BINARY.indexOf(hex) >= 0) ? [] : ['Error'];
  }

  const readFile = (file, filetype) => {
    const blob = file.slice(0, 4);
    tmpReader.onloadend = (evt) => {
      const round2 = validateBinary(evt.target.result);
      if (!round2.length) {
        reader.readAsDataURL(file);
      }
    };

    reader.onload = (evt) => {
      if (filetype == 1) {
        setSrcCV(evt.target.result);
        setFileNameCV(file);
      } if (filetype == 2) {
        setSrcBTN(evt.target.result);
        setFileNameBTN(file);
      } if (filetype == 3) {
        setSrcBDTN(evt.target.result);
        setFileNameBDTN(file);
      } if (filetype == 4) {
        setSrcCCNN(evt.target.result);
        setFileNameCCNN(file);
      } if (filetype == 5) {
        setSrcHC(evt.target.result);
        setFileNameHC(file);
      } if (filetype == 6) {
        setSrcTCT(evt.target.result);
        setFileNameTCT(file);
      } if (filetype == 7) {
        setSrcBLX(evt.target.result);
        setFileNameBLX(file);
      } if (filetype == 8) {
        setSrcCCTA(evt.target.result);
        setFileNameCCTA(file);
      } if (filetype == 9) {
        setSrcCCTN(evt.target.result);
        setFileNameCCTN(file);
      } if (filetype == 10) {
        setSrcCCTH(evt.target.result);
        setFileNameCCTH(file);
      }
    };
    tmpReader.readAsArrayBuffer(blob);
  }

  const onReset = (evt, fileTypeId) => {
    evt.preventDefault();
    evt.stopPropagation();

    if (fileTypeId === 1) {
      setSrcCV('');
      setFileNameCV('');
      setFileCVType('');
    } if (fileTypeId === 2) {
      setSrcBTN('');
      setFileNameBTN('');
      setFileBTNType('');
    } if (fileTypeId === 3) {
      setSrcBDTN('');
      setFileNameBDTN('');
      setFileBDTNType('');
    } if (fileTypeId === 4) {
      setSrcCCNN('');
      setFileNameCCNN('');
      setFileCCNNType('');
    } if (fileTypeId === 5) {
      setSrcHC('');
      setFileNameHC('');
      setFileHCType('');
    } if (fileTypeId === 6) {
      setSrcTCT('');
      setFileNameTCT('');
      setFileTCTType('');
    } if (fileTypeId === 7) {
      setSrcBLX('');
      setFileNameBLX('');
      setFileBLXType('');
    } if (fileTypeId === 8) {
      setSrcCCTA('');
      setFileNameCCTA('');
      setFileCCTAType('');
    } if (fileTypeId === 9) {
      setSrcCCTN('');
      setFileNameCCTN('');
      setFileCCTNType('');
    } if (fileTypeId === 10) {
      setSrcCCTH('');
      setFileNameCCTH('');
      setFileCCTHType('');
    }
  }

  const getIconAllFileUploaded = (fileType) => {
    let icon = '';
    if (fileType == '.pdf') icon = 'pdf';
    else if ((fileType == '.doc') || (fileType == '.docx')) icon = 'doc';
    else if ((fileType == '.xls') || (fileType == '.xlsx')) icon = 'xls';
    else icon = 'forbidden';
    return icon;
  }

  const getIconAllFileUploading = (fileName) => {
    let icon = "";
    switch (fileName.type) {
      case 'application/msword':
        icon = 'doc';
        break;
      case 'application/vnd.openxmlformats-officedocument.wordprocessingml.document':
        icon = 'doc';
        break;
      case 'application/vnd.ms-excel':
        icon = 'xls';
        break;
      case 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet':
        icon = 'xls';
        break;
      case 'application/pdf':
        icon = 'pdf';
        break;
      default:
        icon = 'forbidden';
    }
    return icon;
  }

  const getIconFile = (fileType) => {
    if (fileCVType && fileType === 1) {
      return fileImgCV[getIconAllFileUploaded(fileCVType)];
    } if (fileBTNType && fileType === 2) {
      return fileImgBTN[getIconAllFileUploaded(fileBTNType)];
    } if (fileBDTNType && fileType === 3) {
      return fileImgBDTN[getIconAllFileUploaded(fileBDTNType)];
    } if (fileCCNNType && fileType === 4) {
      return fileImgCCNN[getIconAllFileUploaded(fileCCNNType)];
    } if (fileHCType && fileType === 5) {
      return fileImgHC[getIconAllFileUploaded(fileHCType)];
    } if (fileTCTType && fileType === 6) {
      return fileImgTCT[getIconAllFileUploaded(fileTCTType)];
    } if (fileBLXType && fileType === 7) {
      return fileImgBLX[getIconAllFileUploaded(fileBLXType)];
    } if (fileCCTAType && fileType === 8) {
      return fileImgCCTA[getIconAllFileUploaded(fileCCTAType)];
    } if (fileCCTNType && fileType === 9) {
      return fileImgCCTN[getIconAllFileUploaded(fileCCTNType)];
    } if (fileCCTHType && fileType === 10) {
      return fileImgCCTH[getIconAllFileUploaded(fileCCTHType)];
    }

    if (!fileNameCV && fileType === 1) return '';
    if (!fileNameBTN && fileType === 2) return '';
    if (!fileNameBDTN && fileType === 3) return '';
    if (!fileNameCCNN && fileType === 4) return '';
    if (!fileNameHC && fileType === 5) return '';
    if (!fileNameTCT && fileType === 6) return '';
    if (!fileNameBLX && fileType === 7) return '';
    if (!fileNameCCTA && fileType === 8) return '';
    if (!fileNameCCTN && fileType === 9) return '';
    if (!fileNameCCTH && fileType === 10) return '';

    if (fileNameCV && fileType === 1) {
      return fileImgCV[getIconAllFileUploading(fileNameCV)];
    } if (fileNameBTN && fileType === 2) {
      return fileImgBTN[getIconAllFileUploading(fileNameBTN)];
    } if (fileNameBDTN && fileType === 3) {
      return fileImgBDTN[getIconAllFileUploading(fileNameBDTN)];
    } if (fileNameCCNN && fileType === 4) {
      return fileImgCCNN[getIconAllFileUploading(fileNameCCNN)];
    } if (fileNameHC && fileType === 5) {
      return fileImgHC[getIconAllFileUploading(fileNameHC)];
    } if (fileNameTCT && fileType === 6) {
      return fileImgTCT[getIconAllFileUploading(fileNameTCT)];
    } if (fileNameBLX && fileType === 7) {
      return fileImgBLX[getIconAllFileUploading(fileNameBLX)];
    } if (fileNameCCTA && fileType === 8) {
      return fileImgCCTA[getIconAllFileUploading(fileNameCCTA)];
    } if (fileNameCCTN && fileType === 9) {
      return fileImgCCTN[getIconAllFileUploading(fileNameCCTN)];
    } if (fileNameCCTH && fileType === 10) {
      return fileImgCCTH[getIconAllFileUploading(fileNameCCTH)];
    }
  };

  const templatePreview = (templateId) => {
    setTemplateId(templateId);
    setModalPreview(!modalPreview);
  };

  const createPDF = () => {
    let genderPdf = genderList.filter(g => g.value == gender);
    let marriagePdf = marriageList.filter(m => m.value == marriage);
    let bloodPdf = bloodTypeList.filter(b => b.value == bloodType.value);
    let handPdf = preferredHandList.filter(p => p.value == handedness.value);
    let eyesightPdf = eyesightList.filter(e => e.value == eyesight.value);
    let qualificationPdf = qualificationList.filter(q => q.value == qualifications.value);
    let japanesePdf = certificateJapaneseLevelList.filter(c => c.value == japaneseLevel.value);
    let englishPdf = certificateEnglishLevelList.filter(c => c.value == englishLevel.value);
    let favoriteSubjectPdf = favoriteSubjectList.filter(f => f.value == favoriteSubjects.value);
    let personalityPdf = personalityList.filter(p => p.value == personality.value);
    let strongPointPdf = strongPointList.filter(s => s.value == strongPoints.value);
    let weakPointPdf = weakpointList.filter(w => w.value == weakPoints.value);
    let visaPdf = [];
    if (visaHistories.length > 0 && certificateOfEligibility.value == 2) {
      visaHistories.map((item, key) => {
        let visaTypePdf = visaTypeList.filter(v => v.value == item.visaId.value);
        visaPdf.push({ visaFromDate: item.visaFromDate, visaExpDate: item.visaExpDate, visaType: visaTypePdf[0].label, country: item.country.label });

      })
    } else {
      visaPdf = certificateOfEligibilityList[0].label;
    }

    let data = {
      avatar: avatar ? avatar : userImage2,
      address: address + ", " + cityId.label + ", " + countryId.label,
      fullName: fullname,
      otherName: otherName,
      yourself: yourself,
      email: email,
      phone: "(" + codeArea + ") " + phone,
      gender: genderPdf[0].label,
      marriage: marriagePdf[0].label,
      birthDay: birthDay,
      age: age,
      height: height + " cm",
      weight: weight + " kg",
      bloodType: bloodPdf[0].label,
      handedness: handPdf[0].label,
      eyesight: eyesightPdf[0].label,
      qualifications: qualificationPdf[0].label,
      japaneseLevel: japanesePdf[0].label,
      englishLevel: englishPdf[0].label,
      otherLevel: otherLevel,
      certificateOfEligibility: certificateOfEligibility,
      visaHistories: visaPdf,
      userSkills: userSkills,
      userEducations: userEducations,
      userWorkExps: userWorkExps,
      favoriteSubjects: favoriteSubjectPdf[0].label,
      personality: personalityPdf[0].label,
      strongPoints: strongPointPdf[0].label,
      weakPoints: weakPointPdf[0].label,
    }
    setDataPdf(data);
    setModalSelect(!modalSelect);
    let fullNamePdf = fullname;
    if (fullNamePdf.indexOf(" ") > -1) {
      fullNamePdf = fullNamePdf.replaceAll(" ", "");
    }
    setFileNamePdf(fullNamePdf + "_" + Moment(new Date).format("YYYYMMDDHHmmss"));
  };

  return (
    <React.Fragment>
      {/* {dataPdf && <PDFViewer style={{ with: '100%', height: '100%' }}>
        <TemplateBasic2 data={dataPdf} />
      </PDFViewer>} */}
      {/* LeftSideContent */}
      <Col lg={3}>
        <Card className="profile-sidebar me-lg-4">
          <CardBody className="p-4">
            <div className="text-center pb-4 border-bottom">
              <div className="mb-4 profile-user">
                <img
                  src={avatar ? avatar : userImage2}
                  alt=""
                  className="avatar-lg img-thumbnail rounded-circle mb-4"
                />
                <div className="p-0 rounded-circle profile-photo-edit">
                  <Input
                    id="profile-img-file-input"
                    type="file"
                    className="profile-img-file-input"
                    onChange={handleUploadAvatar}
                  />
                  <Label
                    htmlFor="profile-img-file-input"
                    className="profile-photo-edit avatar-xs"
                  >
                    <i className="uil uil-edit"></i>
                  </Label>
                </div>
              </div>
              <h5 className="mb-0">{fullname}</h5>
              <ul className="list-inline d-flex justify-content-center align-items-center ">
                <li className="list-inline-item text-warning fs-19">
                  <i className="mdi mdi-star"></i>
                  <i className="mdi mdi-star"></i>
                  <i className="mdi mdi-star"></i>
                  <i className="mdi mdi-star"></i>
                  <i className="mdi mdi-star-half-full"></i>
                </li>
              </ul>
              <ul className="candidate-detail-social-menu list-inline mb-0">
                <li className="list-inline-item">
                  <Link
                    onClick={handleOnClickFacebook}
                    className="social-link rounded-3 btn-soft-primary"
                  >
                    <i className="uil uil-facebook-f"></i>
                  </Link>
                </li>
                <li className="list-inline-item">
                  <Link
                    onClick={handleOnClickTwitter}
                    className="social-link rounded-3 btn-soft-info">
                    <i className="uil uil-twitter-alt"></i>
                  </Link>
                </li>
                <li className="list-inline-item">
                  <Link
                    onClick={handleOnClickInstagram}
                    className="social-link rounded-3 btn-soft-danger"
                  >
                    <i className="uil uil-instagram"></i>
                  </Link>
                </li>
                <li className="list-inline-item">
                  <Link
                    onClick={handleOnClickWhatsApp}
                    className="social-link rounded-3 btn-soft-success"
                  >
                    <i className="uil uil-whatsapp"></i>
                  </Link>
                </li>
                <li className="list-inline-item">
                  <Link
                    to="#"
                    className="social-link rounded-3 btn-soft-danger"
                  >
                    <i className="uil uil-phone-alt"></i>
                  </Link>
                </li>
              </ul>
            </div>

            <div className="mt-4">
              <h5 className="fs-17 fw-bold mb-3">{t("createcv.lienlac")}</h5>
              <div className="profile-contact">
                <ul className="list-unstyled mb-0">
                  <li>
                    <div className="d-flex">
                      <label>{t("createcv.email")}</label>
                      <div style={{ marginLeft: -30 }}>
                        <p className="text-muted text-break mb-0">
                          {email}
                        </p>
                      </div>
                    </div>
                  </li>
                  <li>
                    <div className="d-flex">
                      <label>{t("createcv.sodienthoai")}</label>
                      <div style={{ marginLeft: -30 }}>
                        <p className="text-muted mb-0">({codeArea}) {phone}</p>
                      </div>
                    </div>
                  </li>
                  <li>
                    <div className="d-flex">
                      <label>{t("createcv.diachi")}</label>
                      <div style={{ marginLeft: -30 }}>
                        <p className="text-muted mb-0">{cityId.label}, {countryId.label}</p>
                      </div>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </CardBody>
        </Card>
      </Col>

      {/* RightSideContent */}
      <Col lg={9}>
        <Card className="profile-content-page mt-4 mt-lg-0">
          {loading && <Spinners />}
          <Nav
            className="profile-content-nav nav-pills border-bottom mb-4"
            id="pills-tab"
            role="tablist"
          >
            <NavItem role="presentation">
              <NavLink
                to="#"
                className={classnames({ active: activeTab === "1" })}
                onClick={() => {
                  tabChange("1");
                }}
                type="button"
              >
                Lý lịch
              </NavLink>
            </NavItem>
            <NavItem role="presentation">
              <NavLink
                to="#"
                className={classnames({ active: activeTab === "2" })}
                onClick={() => {
                  tabChange("2");
                }}
                type="button"
              >
                Kỹ năng
              </NavLink>
            </NavItem>
            <NavItem role="presentation">
              <NavLink
                to="#"
                className={classnames({ active: activeTab === "3" })}
                onClick={() => {
                  tabChange("3");
                }}
                type="button"
              >
                Đính kèm
              </NavLink>
            </NavItem>
          </Nav>

          <CardBody className="p-4">
            <div className="text-end" style={{ marginTop: -40 }}>
              <button
                className="btn btn-primary"
                onClick={createPDF}
              >
                <i className="uil uil-receipt-alt"></i> Chọn mẫu CV
              </button>
            </div>
            <TabContent activeTab={activeTab}>
              <TabPane tabId="1">
                <div className="mt-4">
                  <h5 className="fs-17 fw-semibold mb-3">{t("createcv.motabanthan")}</h5>
                  <Col lg={7}>
                    <div className="mb-3">
                      <textarea
                        style={{ height: '150px', backgroundColor: background_color }}
                        type="text"
                        className="form-control"
                        id="yourself"
                        onChange={handleYourSelf}
                        value={yourself}
                        placeholder={t("createcv.hoatdongngoaikhoa")}
                      />
                    </div>
                  </Col>
                </div>
                <div className="mt-4">
                  <h5 className="fs-17 fw-semibold mb-3">{t("createcv.tinhtrangsuckhoe")}</h5>
                  <Row>
                    <Col lg={2}>
                      <div className="mb-3">
                        <Label
                          htmlFor="height"
                          className="form-label"
                        >
                          {t("createcv.chieucao")}
                        </Label>
                        <div className="position-relative">
                          <Input
                            style={{ backgroundColor: background_color, paddingLeft: 40 }}
                            type="number"
                            className="form-control"
                            id="height"
                            onChange={handleHeight}
                            value={height}
                          />
                          <Icon
                            style={{
                              position: 'absolute', top: '50%', left: '10px', transform: 'translateY(-50%)', color: 'green',
                              fontSize: '20px'
                            }}
                            icon="mdi:human-male-height" />
                        </div>
                      </div>
                    </Col>
                    <Col lg={2}>
                      <div className="mb-3">
                        <Label
                          htmlFor="weight"
                          className="form-label"
                        >
                          {t("createcv.cannang")}
                        </Label>
                        <div className="position-relative">
                          <Input
                            style={{ backgroundColor: background_color, paddingLeft: 40 }}
                            type="number"
                            className="form-control"
                            id="weight"
                            onChange={handleWeight}
                            value={weight}
                          />
                          <Icon
                            style={{
                              position: 'absolute', top: '50%', left: '10px', transform: 'translateY(-50%)', color: 'green',
                              fontSize: '20px'
                            }}
                            icon="icon-park-outline:weight" />
                        </div>
                      </div>
                    </Col>
                    {eyesight && <Col lg={2}>
                      <div className="mb-3">
                        <Label
                          htmlFor="eyesight"
                          className="form-label"
                        >
                          {t("createcv.thiluc")}
                        </Label>
                        <div className="position-relative">
                          <Select
                            isMulti={false}
                            options={eyesightList}
                            value={eyesight}
                            onChange={handleEyesight}
                            styles={{
                              control: (baseStyles) => ({
                                ...baseStyles,
                                backgroundColor: background_color,
                                borderColor: '#eff0f2',
                                height: '42.6px',
                                fontWeight: 500,
                                paddingLeft: 40
                              }),
                            }}
                            className="react-select-container"
                            classNamePrefix="react-select"
                          />
                          <Icon
                            style={{
                              position: 'absolute', top: '50%', left: '10px', transform: 'translateY(-50%)', color: 'green',
                              fontSize: '20px'
                            }}
                            icon="mdi:eye-outline" />
                        </div>
                      </div>
                    </Col>}
                    {bloodType && <Col lg={2}>
                      <div className="mb-3">
                        <Label
                          htmlFor="bloodType"
                          className="form-label"
                        >
                          {t("createcv.nhommau")}
                        </Label>
                        <div className="position-relative">
                          <Select
                            isMulti={false}
                            options={bloodTypeList}
                            value={bloodType}
                            onChange={handleBloodType}
                            styles={{
                              control: (baseStyles) => ({
                                ...baseStyles,
                                backgroundColor: background_color,
                                borderColor: '#eff0f2',
                                height: '42.6px',
                                fontWeight: 500,
                                paddingLeft: 40
                              }),
                            }}
                            className="react-select-container"
                            classNamePrefix="react-select"
                          />
                          <Icon
                            style={{
                              position: 'absolute', top: '50%', left: '10px', transform: 'translateY(-50%)', color: 'green',
                              fontSize: '20px'
                            }}
                            icon="fontisto:blood-drop" />
                        </div>
                      </div>
                    </Col>}
                    {handedness && <Col lg={2}>
                      <div className="mb-3">
                        <Label
                          htmlFor="handedness"
                          className="form-label"
                        >
                          {t("createcv.taythuan")}
                        </Label>
                        <div className="position-relative">
                          <Select
                            isMulti={false}
                            options={preferredHandList}
                            value={handedness}
                            onChange={handleHandedness}
                            styles={{
                              control: (baseStyles) => ({
                                ...baseStyles,
                                backgroundColor: background_color,
                                borderColor: '#eff0f2',
                                height: '42.6px',
                                fontWeight: 500,
                                paddingLeft: 40
                              }),
                            }}
                            className="react-select-container"
                            classNamePrefix="react-select"
                          />
                          <Icon
                            style={{
                              position: 'absolute', top: '50%', left: '10px', transform: 'translateY(-50%)', color: 'green',
                              fontSize: '20px'
                            }}
                            icon="streamline:hand-grab" />
                        </div>
                      </div>
                    </Col>}
                  </Row>
                </div>

                <div className="mt-4">
                  <h5 className="fs-17 fw-semibold mb-3">{t("createcv.lichsuluutrutainuocngoai")}</h5>
                  {<Row>
                    <Col lg={3}>
                      <div className="mb-3">
                        <label
                          htmlFor="choices-single-location"
                          className="form-label"
                        >
                          {t("createcv.lichsuxinvisa")}
                        </label>
                        <div className="position-relative">
                          <Select
                            isMulti={false}
                            options={certificateOfEligibilityList}
                            value={certificateOfEligibility}
                            onChange={handleCertificateOfEligibility}
                            styles={{
                              control: (baseStyles) => ({
                                ...baseStyles,
                                backgroundColor: background_color,
                                borderColor: '#eff0f2',
                                height: '42.6px',
                                fontWeight: 500,
                                paddingLeft: 40
                              }),
                            }}
                            className="react-select-container"
                            classNamePrefix="react-select"
                          />
                          <Icon
                            style={{
                              position: 'absolute', top: '50%', left: '10px', transform: 'translateY(-50%)', color: 'green',
                              fontSize: '20px'
                            }}
                            icon="formkit:visa" />
                        </div>
                      </div>
                    </Col>
                    {
                      certificateOfEligibility.value == 2 && visaHistories.map((item, index) => (
                        <Row key={index}>
                          <Col lg={2}>
                            <div className="mb-3">
                              <label
                                htmlFor="choices-country"
                                className="form-label"
                              >
                                Nước đã xin Visa
                              </label>
                              <Select
                                isMulti={false}
                                value={item.country}
                                placeholder={'Nước...'}
                                onChange={(event) => handleVisaHistories(event, index, 'country')}
                                styles={{
                                  control: (baseStyles) => ({
                                    ...baseStyles,
                                    backgroundColor: background_color,
                                    borderColor: '#eff0f2',
                                    height: '42.6px',
                                    fontWeight: 500
                                  }),
                                }}
                                options={countryList}
                                className="react-select-container"
                                classNamePrefix="react-select"
                              />
                            </div>
                          </Col>
                          <Col lg={3}>
                            <div className="mb-3">
                              <Label
                                htmlFor="visaId"
                                className="form-label"
                              >
                                Loại visa
                              </Label>
                              <Select
                                isMulti={false}
                                value={item.visaId}
                                onChange={(event) => handleVisaHistories(event, index, 'visaId')}
                                styles={{
                                  control: (baseStyles) => ({
                                    ...baseStyles,
                                    backgroundColor: background_color,
                                    borderColor: '#eff0f2',
                                    height: '42.6px',
                                    fontWeight: 500
                                  }),
                                }}
                                options={visaTypeList}
                                className="react-select-container"
                                classNamePrefix="react-select"
                              />
                            </div>
                          </Col>
                          <Col lg={2}>
                            <div className="mb-3">
                              <Label
                                htmlFor="visaFromDate"
                                className="form-label"
                              >
                                Ngày bắt đầu
                              </Label>
                              <Input
                                style={{ backgroundColor: background_color }}
                                type="date"
                                className="form-control"
                                id="visaFromDate"
                                onChange={(event) => handleVisaHistories(event, index, 'visaFromDate')}
                                value={item.visaFromDate}
                              />
                              {item.visaFromDateErr && <label style={{ color: 'red', fontSize: 14 }}>{item.visaFromDateErr}</label>}
                            </div>
                          </Col>
                          <Col lg={2}>
                            <div className="mb-3">
                              <Label
                                htmlFor="visaExpDate"
                                className="form-label"
                              >
                                {t("visaExpDate")}
                              </Label>
                              <Input
                                style={{ backgroundColor: background_color }}
                                type="date"
                                className="form-control"
                                id="visaExpDate"
                                onChange={(event) => handleVisaHistories(event, index, 'visaExpDate')}
                                value={item.visaExpDate}
                              />
                              {item.visaExpDateErr && <label style={{ color: 'red', fontSize: 14 }}>{item.visaExpDateErr}</label>}
                            </div>
                          </Col>
                          <Col lg={2} style={{ marginTop: 29, padding: "10px" }}>
                            {index === visaHistories.length - 1 && (
                              <button
                                className="btn btn-primary btn-sm"
                                onClick={() => handleAddVisaHistories()}
                              >
                                Thêm
                              </button>
                            )}
                            {visaHistories.length > 1 && (
                              <button
                                className="btn btn-danger btn-sm"
                                onClick={() => handleDeleteVisaHistories(index)}
                              >
                                Xóa
                              </button>
                            )}
                          </Col>
                        </Row>
                      ))
                    }
                  </Row>}
                </div>

                <div className="mt-4">
                  <h5 className="fs-17 fw-semibold mb-3">{t("createcv.quatrinhhoctap")}</h5>
                  {userEducations.map((item, index) => (
                    <Row key={index}>
                      <Col lg={2}>
                        <div className="mb-3">
                          <Label
                            htmlFor="enrollmentMonth"
                            className="form-label"
                          >
                            {t("createcv.nhaphoc")}
                          </Label>
                          <Input
                            style={{ backgroundColor: background_color }}
                            type="date"
                            className="form-control"
                            id="enrollmentMonth"
                            onChange={(event) => handleUserEducations(event, index, 'enrollmentMonth')}
                            value={item.enrollmentMonth}
                          />
                          {item.enrollmentMonthErr && <label style={{ color: 'red', fontSize: 14 }}>{item.enrollmentMonthErr}</label>}
                        </div>
                      </Col>
                      <Col lg={2}>
                        <div className="mb-3">
                          <Label
                            htmlFor="graduationMonth"
                            className="form-label"
                          >
                            {t("createcv.totnghiep")}
                          </Label>
                          <Input
                            style={{ backgroundColor: background_color }}
                            type="date"
                            className="form-control"
                            id="graduationMonth"
                            onChange={(event) => handleUserEducations(event, index, 'graduationMonth')}
                            value={item.graduationMonth}
                          />
                          {item.graduationMonthErr && <label style={{ color: 'red', fontSize: 14 }}>{item.graduationMonthErr}</label>}
                        </div>
                      </Col>
                      <Col lg={8}>
                        <div className="mb-3">
                          <label htmlFor="detail" className="form-label">
                            {t("createcv.tentruong")}
                          </label>
                          <Input
                            style={{ backgroundColor: background_color }}
                            type="text"
                            className="form-control"
                            id="detail"
                            onChange={(event) => handleUserEducations(event, index, 'detail')}
                            value={item.detail}
                            placeholder={t("createcv.tentruongmota")}
                          />
                        </div>
                      </Col>
                      <Col lg={4}>
                        <div className="mb-3">
                          <label htmlFor="major" className="form-label">
                            {t("createcv.nganhhoc")}
                          </label>
                          <Input
                            style={{ backgroundColor: background_color }}
                            type="text"
                            className="form-control"
                            id="major"
                            onChange={(event) => handleUserEducations(event, index, 'major')}
                            value={item.major}
                            placeholder="Tên ngành học"
                          />
                        </div>
                      </Col>
                      <Col lg={4}>
                        <div className="mb-3">
                          <label htmlFor="typeSchool" className="form-label">
                            {t("createcv.loaitruong")}
                          </label>
                          <Select
                            isMulti={false}
                            value={item.typeSchool}
                            onChange={(event) => handleUserEducations(event, index, 'typeSchool')}
                            styles={{
                              control: (baseStyles) => ({
                                ...baseStyles,
                                backgroundColor: background_color,
                                borderColor: '#eff0f2',
                                height: '42.6px',
                                fontWeight: 500
                              }),
                            }}
                            options={educationLevelList}
                            className="react-select-container"
                            classNamePrefix="react-select"
                          />
                        </div>
                      </Col>
                      <Col lg={4}>
                        <div className="mb-3">
                          <Label
                            htmlFor="graduateStatus"
                            className="form-label"
                          >
                            {t("createcv.tinhtrangtotnghiep")}
                          </Label>
                          <Select
                            isMulti={false}
                            value={item.graduateStatus}
                            onChange={(event) => handleUserEducations(event, index, 'graduateStatus')}
                            styles={{
                              control: (baseStyles) => ({
                                ...baseStyles,
                                backgroundColor: background_color,
                                borderColor: '#eff0f2',
                                height: '42.6px',
                                fontWeight: 500
                              }),
                            }}
                            options={graduatestatusList}
                            className="react-select-container"
                            classNamePrefix="react-select"
                          />
                        </div>
                      </Col>
                      <Col lg={4}>
                        <div className="mb-3">
                          {index === userEducations.length - 1 && (
                            <button
                              className="btn btn-primary btn-sm"
                              onClick={() => handleAddUserEducations()}
                            >
                              {t("createcv.themquatrinhhoctap")}
                            </button>
                          )}
                          {userEducations.length > 1 && (
                            <button
                              className="btn btn-danger btn-sm"
                              onClick={() => handleDeleteUserEducations(index)}
                            >
                              Xóa
                            </button>
                          )}
                        </div>
                      </Col>
                    </Row>
                  ))}
                </div>
                <div className="mt-4">
                  <h5 className="fs-17 fw-semibold mb-3">{t("createcv.quatrinhlamviec")}</h5>
                  {userWorkExps.map((item, index) => (
                    <Row key={index}>
                      <Col lg={2}>
                        <div className="mb-3">
                          <Label
                            htmlFor="startMonth"
                            className="form-label"
                          >
                            {t("createcv.vaolam")}
                          </Label>
                          <Input
                            style={{ backgroundColor: background_color }}
                            type="date"
                            className="form-control"
                            id="startMonth"
                            onChange={(event) => handleUserWorkExps(event, index, 'startMonth')}
                            value={item.startMonth}
                          />
                          {item.startMonthErr && <label style={{ color: 'red', fontSize: 14 }}>{item.startMonthErr}</label>}
                        </div>
                      </Col>
                      <Col lg={2}>
                        <div className="mb-3">
                          <Label
                            htmlFor="endMonth"
                            className="form-label"
                          >
                            {t("createcv.nghiviec")}
                          </Label>
                          <Input
                            style={{ backgroundColor: background_color }}
                            type="date"
                            className="form-control"
                            id="endMonth"
                            onChange={(event) => handleUserWorkExps(event, index, 'endMonth')}
                            value={item.endMonth}
                          />
                          {item.endMonthErr && <label style={{ color: 'red', fontSize: 14 }}>{item.endMonthErr}</label>}
                        </div>
                      </Col>
                      <Col lg={5}>
                        <div className="mb-3">
                          <label htmlFor="company" className="form-label">
                            {t("createcv.tencongty")}
                          </label>
                          <Input
                            style={{ backgroundColor: background_color }}
                            type="text"
                            className="form-control"
                            id="company"
                            onChange={(event) => handleUserWorkExps(event, index, 'company')}
                            value={item.company}
                            placeholder={t("createcv.tencongtymota")}
                          />
                        </div>
                      </Col>
                      <Col lg={2}>
                        <div className="mb-3">
                          <label htmlFor="position" className="form-label">
                            {t("createcv.chucvu")}
                          </label>
                          <Select
                            isMulti={false}
                            value={item.position}
                            onChange={(event) => handleUserWorkExps(event, index, 'position')}
                            styles={{
                              control: (baseStyles) => ({
                                ...baseStyles,
                                backgroundColor: background_color,
                                borderColor: '#eff0f2',
                                height: '42.6px',
                                fontWeight: 500
                              }),
                            }}
                            options={positionList}
                            className="react-select-container"
                            classNamePrefix="react-select"
                          />
                        </div>
                      </Col>
                      <Col lg={7}>
                        <div className="mb-3">
                          <label htmlFor="jobDesc" className="form-label">
                            {t("createcv.motacongviec")}
                          </label>
                          <textarea
                            style={{ backgroundColor: background_color, height: '150px' }}
                            type="text"
                            className="form-control"
                            id="jobDesc"
                            onChange={(event) => handleUserWorkExps(event, index, 'jobDesc')}
                            value={item.jobDesc}
                            placeholder={t("createcv.nhapmotacongviec")}
                          />
                        </div>
                      </Col>
                      <Col lg={3}>
                        <div className="mt-4 mb-3">
                          {index === userWorkExps.length - 1 && (
                            <button
                              className="btn btn-primary btn-sm"
                              onClick={() => handleAddUserWorkExps()}
                            >
                              {t("createcv.themquatrinhlamviec")}
                            </button>
                          )}
                          {userWorkExps.length > 1 && (
                            <button
                              className="btn btn-danger btn-sm"
                              onClick={() => handleDeleteUserWorkExps(index)}
                            >
                              Xóa
                            </button>
                          )}
                        </div>
                      </Col>
                    </Row>
                  ))}
                </div>
                <div className="mt-4 text-end">
                  <button
                    className="btn btn-primary"
                    onClick={handleUpdateProfile}
                    disabled={invalid}
                  >
                    <i className="uil uil-save"></i> {t("account.capnhat")}
                  </button>
                </div>
              </TabPane>
              <TabPane tabId="2">
                <div className="mt-4">
                  <Row>
                    <Col lg={3}>
                      <div className="mb-3">
                        <Label htmlFor="qualifications" className="form-label">
                          {t("createcv.bangcapchungchi")}
                        </Label>
                        <Select
                          isMulti={false}
                          value={qualifications}
                          onChange={handleQualifications}
                          styles={{
                            control: (baseStyles) => ({
                              ...baseStyles,
                              backgroundColor: background_color,
                              borderColor: '#eff0f2',
                              height: '42.6px',
                              fontWeight: 500
                            }),
                          }}
                          options={qualificationList}
                          className="react-select-container"
                          classNamePrefix="react-select"
                        />
                      </div>
                    </Col>
                    <Col lg={3}>
                      <div className="mb-3">
                        <Label
                          htmlFor="japaneseLevel"
                          className="form-label"
                        >
                          {t("createcv.tiengnhat")}
                        </Label>
                        <Select
                          isMulti={false}
                          value={japaneseLevel}
                          placeholder={'Tiếng Nhật...'}
                          onChange={handleJapaneseLevel}
                          styles={{
                            control: (baseStyles) => ({
                              ...baseStyles,
                              backgroundColor: background_color,
                              borderColor: '#eff0f2',
                              height: '42.6px',
                              fontWeight: 500
                            }),
                          }}
                          options={certificateJapaneseLevelList}
                          className="react-select-container"
                          classNamePrefix="react-select"
                        />
                      </div>
                    </Col>
                    <Col lg={3}>
                      <div className="mb-3">
                        <Label
                          htmlFor="englishLevel"
                          className="form-label"
                        >
                          {t("createcv.tienganh")}
                        </Label>
                        <Select
                          isMulti={false}
                          value={englishLevel}
                          placeholder={'Tiếng Anh...'}
                          onChange={handleEnglishLevel}
                          styles={{
                            control: (baseStyles) => ({
                              ...baseStyles,
                              backgroundColor: background_color,
                              borderColor: '#eff0f2',
                              height: '42.6px',
                              fontWeight: 500
                            }),
                          }}
                          options={certificateEnglishLevelList}
                          className="react-select-container"
                          classNamePrefix="react-select"
                        />
                      </div>
                    </Col>
                    <Col lg={3}>
                      <div className="mb-3">
                        <Label
                          htmlFor="otherLevel"
                          className="form-label"
                        >
                          {t("createcv.tiengkhac")}
                        </Label>
                        <Input
                          style={{ backgroundColor: background_color }}
                          type="text"
                          className="form-control"
                          id="otherLevel"
                          onChange={handleOtherLevel}
                          value={otherLevel}
                        />
                      </div>
                    </Col>
                  </Row>
                </div>
                <div className="mt-4">
                  <h5 className="fs-17 fw-semibold mb-3">{t("createcv.prbanthan")}</h5>
                  <Row>
                    {favoriteSubjects && <Col lg={3}>
                      <div className="mb-3">
                        <Label
                          htmlFor="favoriteSubjects"
                          className="form-label"
                        >
                          {t("createcv.monhocsotruong")}
                        </Label>
                        <Select
                          isMulti={false}
                          value={favoriteSubjects}
                          onChange={handleFavoriteSubjects}
                          styles={{
                            control: (baseStyles) => ({
                              ...baseStyles,
                              backgroundColor: background_color,
                              borderColor: '#eff0f2',
                              height: '42.6px',
                              fontWeight: 500
                            }),
                          }}
                          options={favoriteSubjectList}
                          className="react-select-container"
                          classNamePrefix="react-select"
                        />
                      </div>
                    </Col>}
                    {personality && <Col lg={3}>
                      <div className="mb-3">
                        <Label
                          htmlFor="personality"
                          className="form-label"
                        >
                          {t("personality")}
                        </Label>
                        <Select
                          isMulti={false}
                          value={personality}
                          onChange={handlePersonality}
                          styles={{
                            control: (baseStyles) => ({
                              ...baseStyles,
                              backgroundColor: background_color,
                              borderColor: '#eff0f2',
                              height: '42.6px',
                              fontWeight: 500
                            }),
                          }}
                          options={personalityList}
                          className="react-select-container"
                          classNamePrefix="react-select"
                        />
                      </div>
                    </Col>}
                    {strongPoints && <Col lg={3}>
                      <div className="mb-3">
                        <Label
                          htmlFor="strongPoint"
                          className="form-label"
                        >
                          {t("createcv.sotruong")}
                        </Label>
                        <Select
                          isMulti={false}
                          value={strongPoints}
                          onChange={handleStrongPoint}
                          styles={{
                            control: (baseStyles) => ({
                              ...baseStyles,
                              backgroundColor: background_color,
                              borderColor: '#eff0f2',
                              height: '42.6px',
                              fontWeight: 500
                            }),
                          }}
                          options={strongPointList}
                          className="react-select-container"
                          classNamePrefix="react-select"
                        />
                      </div>
                    </Col>}
                    {weakPoints && <Col lg={3}>
                      <div className="mb-3">
                        <Label
                          htmlFor="weakPoints"
                          className="form-label"
                        >
                          {t("createcv.sodoan")}
                        </Label>
                        <Select
                          isMulti={false}
                          value={weakPoints}
                          onChange={handleWeakPoints}
                          styles={{
                            control: (baseStyles) => ({
                              ...baseStyles,
                              backgroundColor: background_color,
                              borderColor: '#eff0f2',
                              height: '42.6px',
                              fontWeight: 500
                            }),
                          }}
                          options={weakpointList}
                          className="react-select-container"
                          classNamePrefix="react-select"
                        />
                      </div>
                    </Col>}
                  </Row>
                </div>
                <div className="mt-4">
                  <h5 className="fs-17 fw-semibold mb-3">{t("createcv.kynangnganhnghe")}</h5>
                  {userSkills.map((item, index) => (
                    <Row key={index}>
                      <Col lg={3}>
                        <div className="mb-3">
                          <Label
                            htmlFor="industry"
                            className="form-label"
                          >
                            {t("createcv.nganhnghe")}
                          </Label>
                          <Select
                            className="react-select-container"
                            classNamePrefix="react-select"
                            isMulti={false}
                            id="industry"
                            value={{ value: item.industry, label: item.labelIndustry }}
                            placeholder={'Chọn ngành nghề...'}
                            onChange={(event) => handleUserSkills(event, index, 'industry')}
                            styles={{
                              control: (baseStyles) => ({
                                ...baseStyles,
                                backgroundColor: background_color,
                                borderColor: '#eff0f2',
                                height: '42.6px',
                                fontWeight: 500
                              }),
                            }}
                            options={industryList}
                          />
                        </div>
                      </Col>
                      <Col lg={4}>
                        <div className="mb-3">
                          <Label
                            htmlFor="fields"
                            className="form-label"
                          >
                            {t("createcv.chuyenmon")}
                          </Label>
                          <Select
                            className="react-select-container"
                            classNamePrefix="react-select"
                            isMulti={false}
                            value={{ value: item.fields, label: item.labelField }}
                            placeholder={'Chọn chuyên môn...'}
                            onChange={(event) => handleUserSkills(event, index, 'fields')}
                            styles={{
                              control: (baseStyles) => ({
                                ...baseStyles,
                                backgroundColor: background_color,
                                borderColor: '#eff0f2',
                                height: '42.6px',
                                fontWeight: 500
                              }),
                            }}
                            options={fieldsList}
                          />
                        </div>
                      </Col>
                      <Col lg={5}>
                        <div className="mb-3">
                          <Label
                            htmlFor="industry"
                            className="form-label"
                          >
                            {t("createcv.kynang")}
                          </Label>
                          <Select
                            className="react-select-container"
                            classNamePrefix="react-select"
                            isMulti={false}
                            value={{ value: item.skill, label: item.labelSkill }}
                            placeholder={'Chọn kỹ năng...'}
                            onChange={(event) => handleUserSkills(event, index, 'skill')}
                            styles={{
                              control: (baseStyles) => ({
                                ...baseStyles,
                                backgroundColor: background_color,
                                borderColor: '#eff0f2',
                                height: '42.6px',
                                fontWeight: 500
                              }),
                            }}
                            options={skillList}
                          />
                        </div>
                        <div className="mt-4 text-end mb-3">
                          {index === userSkills.length - 1 && (
                            <button
                              className="btn btn-primary btn-sm"
                              onClick={() => handleAddUserSkills()}
                            >
                              Thêm
                            </button>
                          )}
                          {userSkills.length > 1 && (
                            <button
                              className="btn btn-danger btn-sm"
                              onClick={() => handleDeleteUserSkills(index)}
                            >
                              Xóa
                            </button>
                          )}
                        </div>
                      </Col>
                    </Row>
                  ))}
                </div>
                <div className="mt-4 text-end">
                  <button
                    className="btn btn-primary"
                    onClick={handleUpdateProfile}
                    disabled={invalid}
                  >
                    <i className="uil uil-save"></i> {t("account.capnhat")}
                  </button>
                </div>
              </TabPane>
              <TabPane tabId="3">
                <div className="mt-4">
                  <Row>
                    <Col lg={3}>
                      <div className="mb-3">
                        <div className="uploadfile">
                          <Label className="form-label fs-17 fw-semibold">
                            {t("createcv.cv")}
                          </Label>
                          <label htmlFor="hscv"
                            className={[!srcCV ? "uploadfile__label" : "uploadfiled__label"]}
                          >
                          </label>
                          <input
                            className="uploadfile__input"
                            type="file"
                            id="hscv"
                            onChange={(e) => handleUploadFile(e, 1)}
                          />
                          {srcCV && <p className="uploadfile__image break-overflow pt-custom">{fileNameCV && fileNameCV.name}</p>}
                          {(fileCVType || fileNameCV) && <a href="false" onClick={(event) => onReset(event, 1)} className="uploadfile__close">
                            <i className="uil uil-times"></i>
                          </a>}
                          {
                            <div className="img-file">
                              {
                                <img alt="" className={[(fileCVType || fileNameCV) ? "uploadfile__image" : "uploadfiled__image"]} src={getIconFile(1)} />
                              }
                            </div>
                          }
                        </div>
                        <div style={{ display: "flex", justifyContent: "center" }}>
                          <button
                            type="button"
                            className="mb-2 btn-soft-primary btn-sm btn-hover"
                            style={{ borderRadius: "5px" }}
                            onClick={() => downloadFileImported(fileCVPath, fileCVType, "CV")}
                          >
                            <i className="mdi mdi-download"></i> {t("dinhkem.taixuong")}
                          </button>
                        </div>
                        {fileCV && <label style={{ color: 'green', fontSize: 14 }}>{fileCV}</label>}
                      </div>
                    </Col>
                    <Col lg={3}>
                      <div className="mb-3">
                        <div className="uploadfile">
                          <Label className="form-label fs-17 fw-semibold">
                            {t("createcv.bangtotnghiep")}
                          </Label>
                          <label htmlFor="btn"
                            className={[!srcBTN ? "uploadfile__label" : "uploadfiled__label"]}
                          >
                          </label>
                          <input
                            className="uploadfile__input"
                            type="file"
                            id="btn"
                            onChange={(e) => handleUploadFile(e, 2)}
                          />
                          {srcBTN && <p className="uploadfile__image break-overflow pt-custom">{fileNameBTN && fileNameBTN.name}</p>}
                          {(fileBTNType || fileNameBTN) && <a href="false" onClick={(event) => onReset(event, 2)} className="uploadfile__close">
                            <i className="uil uil-times"></i>
                          </a>}
                          {
                            <div className="img-file">
                              {
                                <img alt="" className={[(fileBTNType || fileNameBTN) ? "uploadfile__image" : "uploadfiled__image"]} src={getIconFile(2)} />
                              }
                            </div>
                          }
                        </div>
                        {
                          fileBTNPath && <div style={{ display: "flex", justifyContent: "center" }}>
                            <button
                              type="button"
                              className="mb-2 btn-soft-primary btn-sm btn-hover"
                              style={{ borderRadius: "5px" }}
                              onClick={() => downloadFileImported(fileBTNPath, fileBTNType, "BTN")}
                            >
                              <i className="mdi mdi-download"></i> {t("dinhkem.taixuong")}
                            </button>
                          </div>
                        }
                        {fileBTN && <label style={{ color: 'green', fontSize: 14 }}>{fileBTN}</label>}
                      </div>
                    </Col>
                    <Col lg={3}>
                      <div className="mb-3">
                        <div className="uploadfile">
                          <Label className="form-label fs-17 fw-semibold">
                            Bảng điểm tốt nghiệp
                          </Label>
                          <label htmlFor="bdtn"
                            className={[!srcBDTN ? "uploadfile__label" : "uploadfiled__label"]}
                          >
                          </label>
                          <input
                            className="uploadfile__input"
                            type="file"
                            id="bdtn"
                            onChange={(e) => handleUploadFile(e, 3)}
                          />
                          {srcBDTN && <p className="uploadfile__image break-overflow pt-custom">{fileNameBDTN && fileNameBDTN.name}</p>}
                          {(fileBDTNType || fileNameBDTN) && <a href="false" onClick={(event) => onReset(event, 3)} className="uploadfile__close">
                            <i className="uil uil-times"></i>
                          </a>}
                          {
                            <div className="img-file">
                              {
                                <img alt="" className={[(fileBDTNType || fileNameBDTN) ? "uploadfile__image" : "uploadfiled__image"]} src={getIconFile(3)} />
                              }
                            </div>
                          }
                        </div>
                        {
                          fileBDTNPath && <div style={{ display: "flex", justifyContent: "center" }}>
                            <button
                              type="button"
                              className="mb-2 btn-soft-primary btn-sm btn-hover"
                              style={{ borderRadius: "5px" }}
                              onClick={() => downloadFileImported(fileBDTNPath, fileBDTNType, "DBTN")}
                            >
                              <i className="mdi mdi-download"></i> {t("dinhkem.taixuong")}
                            </button>
                          </div>
                        }
                        {fileBDTN && <label style={{ color: 'green', fontSize: 14 }}>{fileBDTN}</label>}
                      </div>
                    </Col>
                    <Col lg={3}>
                      <div className="mb-3">
                        <div className="uploadfile">
                          <Label className="form-label fs-17 fw-semibold">
                            {t("createcv.ccngoaingucaonhat")}
                          </Label>
                          <label htmlFor="hscc"
                            className={[!srcCCNN ? "uploadfile__label" : "uploadfiled__label"]}
                          >
                          </label>
                          <input
                            className="uploadfile__input"
                            type="file"
                            id="hscc"
                            onChange={(e) => handleUploadFile(e, 4)}
                          />
                          {srcCCNN && <p className="uploadfile__image break-overflow pt-custom">{fileNameCCNN && fileNameCCNN.name}</p>}
                          {(fileCCNNType || fileNameCCNN) && <a href="false" onClick={(event) => onReset(event, 4)} className="uploadfile__close">
                            <i className="uil uil-times"></i>
                          </a>}
                          {
                            <div className="img-file">
                              {
                                <img alt="" className={[(fileCCNNType || fileNameCCNN) ? "uploadfile__image" : "uploadfiled__image"]} src={getIconFile(4)} />
                              }
                            </div>
                          }
                        </div>
                        {
                          fileCCNNPath && <div style={{ display: "flex", justifyContent: "center" }}>
                            <button
                              type="button"
                              className="mb-2 btn-soft-primary btn-sm btn-hover"
                              style={{ borderRadius: "5px" }}
                              onClick={() => downloadFileImported(fileCCNNPath, fileCCNNType, "CCNN")}
                            >
                              <i className="mdi mdi-download"></i> {t("dinhkem.taixuong")}
                            </button>
                          </div>
                        }
                        {fileCCNN && <label style={{ color: 'green', fontSize: 14 }}>{fileCCNN}</label>}
                      </div>
                    </Col>
                    <Col lg={3}>
                      <div className="mb-3">
                        <div className="uploadfile">
                          <Label className="form-label fs-17 fw-semibold">
                            {t("createcv.hochieu")}
                          </Label>
                          <label htmlFor="hshc"
                            className={[!srcHC ? "uploadfile__label" : "uploadfiled__label"]}
                          >
                          </label>
                          <input
                            className="uploadfile__input"
                            type="file"
                            id="hshc"
                            onChange={(e) => handleUploadFile(e, 5)}
                          />
                          {srcHC && <p className="uploadfile__image break-overflow pt-custom">{fileNameHC && fileNameHC.name}</p>}
                          {(fileHCType || fileNameHC) && <a href="false" onClick={(event) => onReset(event, 5)} className="uploadfile__close">
                            <i className="uil uil-times"></i>
                          </a>}
                          {
                            <div className="img-file">
                              {
                                <img alt="" className={[(fileHCType || fileNameHC) ? "uploadfile__image" : "uploadfiled__image"]} src={getIconFile(5)} />
                              }
                            </div>
                          }
                        </div>
                        {
                          fileHCPath && <div style={{ display: "flex", justifyContent: "center" }}>
                            <button
                              type="button"
                              className="mb-2 btn-soft-primary btn-sm btn-hover"
                              style={{ borderRadius: "5px" }}
                              onClick={() => downloadFileImported(fileHCPath, fileHCType, "HC")}
                            >
                              <i className="mdi mdi-download"></i> {t("dinhkem.taixuong")}
                            </button>
                          </div>
                        }
                        {fileHC && <label style={{ color: 'green', fontSize: 14 }}>{fileHC}</label>}
                      </div>
                    </Col>
                    <Col lg={3}>
                      <div className="mb-3">
                        <div className="uploadfile">
                          <Label className="form-label fs-17 fw-semibold">
                            {t("createcv.visahoacthecutru")}
                          </Label>
                          <label htmlFor="hstct"
                            className={[!srcTCT ? "uploadfile__label" : "uploadfiled__label"]}
                          >
                          </label>
                          <input
                            className="uploadfile__input"
                            type="file"
                            id="hstct"
                            onChange={(e) => handleUploadFile(e, 6)}
                          />
                          {srcTCT && <p className="uploadfile__image break-overflow pt-custom">{fileNameTCT && fileNameTCT.name}</p>}
                          {(fileTCTType || fileNameTCT) && <a href="false" onClick={(event) => onReset(event, 6)} className="uploadfile__close">
                            <i className="uil uil-times"></i>
                          </a>}
                          {
                            <div className="img-file">
                              {
                                <img alt="" className={[(fileTCTType || fileNameTCT) ? "uploadfile__image" : "uploadfiled__image"]} src={getIconFile(6)} />
                              }
                            </div>
                          }
                        </div>
                        {
                          fileTCTPath && <div style={{ display: "flex", justifyContent: "center" }}>
                            <button
                              type="button"
                              className="mb-2 btn-soft-primary btn-sm btn-hover"
                              style={{ borderRadius: "5px" }}
                              onClick={() => downloadFileImported(fileTCTPath, fileTCTType, "TCT")}
                            >
                              <i className="mdi mdi-download"></i> {t("dinhkem.taixuong")}
                            </button>
                          </div>
                        }
                        {fileTCT && <label style={{ color: 'green', fontSize: 14 }}>{fileTCT}</label>}
                      </div>
                    </Col>
                    <Col lg={3}>
                      <div className="mb-3">
                        <div className="uploadfile">
                          <Label className="form-label fs-17 fw-semibold">
                            {t("createcv.banglaixe")}
                          </Label>
                          <label htmlFor="hsblx"
                            className={[!srcBLX ? "uploadfile__label" : "uploadfiled__label"]}
                          >
                          </label>
                          <input
                            className="uploadfile__input"
                            type="file"
                            id="hsblx"
                            onChange={(e) => handleUploadFile(e, 7)}
                          />
                          {srcBLX && <p className="uploadfile__image break-overflow pt-custom">{fileNameBLX && fileNameBLX.name}</p>}
                          {(fileBLXType || fileNameBLX) && <a href="false" onClick={(event) => onReset(event, 7)} className="uploadfile__close">
                            <i className="uil uil-times"></i>
                          </a>}
                          {
                            <div className="img-file">
                              {
                                <img alt="" className={[(fileBLXType || fileNameBLX) ? "uploadfile__image" : "uploadfiled__image"]} src={getIconFile(7)} />
                              }
                            </div>
                          }
                        </div>
                        {
                          fileBLXPath && <div style={{ display: "flex", justifyContent: "center" }}>
                            <button
                              type="button"
                              className="mb-2 btn-soft-primary btn-sm btn-hover"
                              style={{ borderRadius: "5px" }}
                              onClick={() => downloadFileImported(fileBLXPath, fileBLXType, "BLX")}
                            >
                              <i className="mdi mdi-download"></i> {t("dinhkem.taixuong")}
                            </button>
                          </div>
                        }
                        {fileBLX && <label style={{ color: 'green', fontSize: 14 }}>{fileBLX}</label>}
                      </div>
                    </Col>
                    <Col lg={3}>
                      <div className="mb-3">
                        <div className="uploadfile">
                          <Label className="form-label fs-17 fw-semibold">
                            {t("createcv.chungchitienganh")}
                          </Label>
                          <label htmlFor="ccta"
                            className={[!srcCCTA ? "uploadfile__label" : "uploadfiled__label"]}
                          >
                          </label>
                          <input
                            className="uploadfile__input"
                            type="file"
                            id="ccta"
                            onChange={(e) => handleUploadFile(e, 8)}
                          />
                          {srcCCTA && <p className="uploadfile__image break-overflow pt-custom">{fileNameCCTA && fileNameCCTA.name}</p>}
                          {(fileCCTAType || fileNameCCTA) && <a href="false" onClick={(event) => onReset(event, 8)} className="uploadfile__close">
                            <i className="uil uil-times"></i>
                          </a>}
                          {
                            <div className="img-file">
                              {
                                <img alt="" className={[(fileCCTAType || fileNameCCTA) ? "uploadfile__image" : "uploadfiled__image"]} src={getIconFile(8)} />
                              }
                            </div>
                          }
                        </div>
                        {
                          fileCCTAPath && <div style={{ display: "flex", justifyContent: "center" }}>
                            <button
                              type="button"
                              className="mb-2 btn-soft-primary btn-sm btn-hover"
                              style={{ borderRadius: "5px" }}
                              onClick={() => downloadFileImported(fileCCTAPath, fileCCTAType, "CCTA")}
                            >
                              <i className="mdi mdi-download"></i> {t("dinhkem.taixuong")}
                            </button>
                          </div>
                        }
                        {fileCCTA && <label style={{ color: 'green', fontSize: 14 }}>{fileCCTA}</label>}
                      </div>
                    </Col>
                    <Col lg={3}>
                      <div className="mb-3">
                        <div className="uploadfile">
                          <Label className="form-label fs-17 fw-semibold">
                            {t("createcv.chungchitiengnhat")}
                          </Label>
                          <label htmlFor="cctn"
                            className={[!srcCCTN ? "uploadfile__label" : "uploadfiled__label"]}
                          >
                          </label>
                          <input
                            className="uploadfile__input"
                            type="file"
                            id="cctn"
                            onChange={(e) => handleUploadFile(e, 9)}
                          />
                          {srcCCTN && <p className="uploadfile__image break-overflow pt-custom">{fileNameCCTN && fileNameCCTN.name}</p>}
                          {(fileCCTNType || fileNameCCTN) && <a href="false" onClick={(event) => onReset(event, 9)} className="uploadfile__close">
                            <i className="uil uil-times"></i>
                          </a>}
                          {
                            <div className="img-file">
                              {
                                <img alt="" className={[(fileCCTNType || fileNameCCTN) ? "uploadfile__image" : "uploadfiled__image"]} src={getIconFile(9)} />
                              }
                            </div>
                          }
                        </div>
                        {
                          fileCCTNPath && <div style={{ display: "flex", justifyContent: "center" }}>
                            <button
                              type="button"
                              className="mb-2 btn-soft-primary btn-sm btn-hover"
                              style={{ borderRadius: "5px" }}
                              onClick={() => downloadFileImported(fileCCTNPath, fileCCTNType, "CCTN")}
                            >
                              <i className="mdi mdi-download"></i> {t("dinhkem.taixuong")}
                            </button>
                          </div>
                        }
                        {fileCCTN && <label style={{ color: 'green', fontSize: 14 }}>{fileCCTN}</label>}
                      </div>
                    </Col>
                    <Col lg={3}>
                      <div className="mb-3">
                        <div className="uploadfile">
                          <Label className="form-label fs-17 fw-semibold">
                            {t("createcv.chungchitienghan")}
                          </Label>
                          <label htmlFor="ccth"
                            className={[!srcCCTH ? "uploadfile__label" : "uploadfiled__label"]}
                          >
                          </label>
                          <input
                            className="uploadfile__input"
                            type="file"
                            id="ccth"
                            onChange={(e) => handleUploadFile(e, 10)}
                          />
                          {srcCCTH && <p className="uploadfile__image break-overflow pt-custom">{fileNameCCTH && fileNameCCTH.name}</p>}
                          {(fileCCTHType || fileNameCCTH) && <a href="false" onClick={(event) => onReset(event, 10)} className="uploadfile__close">
                            <i className="uil uil-times"></i>
                          </a>}
                          {
                            <div className="img-file">
                              {
                                <img alt="" className={[(fileCCTHType || fileNameCCTH) ? "uploadfile__image" : "uploadfiled__image"]} src={getIconFile(10)} />
                              }
                            </div>
                          }
                        </div>
                        {
                          fileCCTHPath && <div style={{ display: "flex", justifyContent: "center" }}>
                            <button
                              type="button"
                              className="mb-2 btn-soft-primary btn-sm btn-hover"
                              style={{ borderRadius: "5px" }}
                              onClick={() => downloadFileImported(fileCCTHPath, fileCCTHType, "CCTH")}
                            >
                              <i className="mdi mdi-download"></i> {t("dinhkem.taixuong")}
                            </button>
                          </div>
                        }
                        {fileCCTH && <label style={{ color: 'green', fontSize: 14 }}>{fileCCTH}</label>}
                      </div>
                    </Col>
                  </Row>
                </div>
              </TabPane>
            </TabContent>
          </CardBody>
        </Card>
        {alertsuccess && <AlertsSuccess message={MESSAGE_VALIDATE.update_profile_success} />}
        {alertdanger && <Alerts message={MESSAGE_VALIDATE.update_profile_fail} />}
        {alertvalidate && <Alerts message={MESSAGE_VALIDATE.update_profile_validate} />}
      </Col>

      <Modal isOpen={modalSelect} toggle={openModalSelect} role="dialog" centered backdrop="static" scrollable={true} size="xl"
        style={{ width: "990px" }}>
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title mt-0" id="myModalLabel">
              Chọn mẫu CV
            </h5>
            <button
              type="button"
              className="btn-close"
              onClick={openModalSelect}
            ></button>
          </div>
          <ModalBody>
            <Row className="align-items-center">
              <Col lg={3} md={6}>
                <div className="blog-box card p-2 mt-3" style={{ width: '200px', height: '300px' }}>
                  <div className="blog-img position-relative overflow-hidden">
                    <img src={template1} alt="" className="img-fluid" />
                    <div className="bg-overlay"></div>
                  </div>
                  <div className="card-body">
                    <Row className="align-items-center">
                      <button
                        type="button"
                        className="mb-2 btn-soft-primary btn-sm btn-hover"
                        style={{ borderRadius: "5px" }}
                        onClick={() => templatePreview(1)}>
                        <i className="mdi mdi-eye"></i> Xem trước
                      </button>
                      <div className="btn-downloadpdf btn-hover">
                        <i className="uil uil-import" style={{ color: "white" }}></i>
                        <PDFDownloadLink
                          document={<TemplateBasic1 data={dataPdf} />}
                          fileName={filenamePdf}
                          style={{
                            textDecoration: "none",
                            padding: "10px",
                            color: "#FFFFFF",
                            backgroundColor: "#02af74",
                            border: "none",
                            borderRadius: "5px",
                            width: "100px",
                          }}
                        >
                          {({ blob, url, loading, error }) =>
                            (loading && templateId == 0) ? "Loading..." : "Tải xuống CV"
                          }
                        </PDFDownloadLink>
                      </div>
                    </Row>
                  </div>
                </div>
              </Col>
              <Col lg={3} md={6}>
                <div className="blog-box card p-2 mt-3" style={{ width: '200px', height: '300px' }}>
                  <div className="blog-img position-relative overflow-hidden">
                    <img src={template2} alt="" className="img-fluid" />
                    <div className="bg-overlay"></div>
                  </div>
                  <div className="card-body">
                    <Row className="align-items-center">
                      <button
                        type="button"
                        className="mb-2 btn-soft-primary btn-sm btn-hover"
                        style={{ borderRadius: "5px" }}
                        onClick={() => templatePreview(2)}>
                        <i className="mdi mdi-eye"></i> Xem trước
                      </button>
                      <div className="btn-downloadpdf btn-hover">
                        <i className="uil uil-import" style={{ color: "white" }}></i>
                        <PDFDownloadLink
                          document={<TemplateBasic2 data={dataPdf} />}
                          fileName={filenamePdf}
                          style={{
                            textDecoration: "none",
                            padding: "10px",
                            color: "#FFFFFF",
                            backgroundColor: "#02af74",
                            border: "none",
                            borderRadius: "5px",
                            width: "100px",
                          }}
                        >
                          {({ blob, url, loading, error }) =>
                            (loading && templateId == 0) ? "Loading..." : "Tải xuống CV"
                          }
                        </PDFDownloadLink>
                      </div>
                    </Row>
                  </div>
                </div>
              </Col>
              <Col lg={3} md={6}>
                <div className="blog-box card p-2 mt-3" style={{ width: '200px', height: '300px' }}>
                  <div className="blog-img position-relative overflow-hidden">
                    <img src={template3} alt="" className="img-fluid" />
                    <div className="bg-overlay"></div>
                  </div>
                  <div className="card-body">
                    <Row className="align-items-center">
                      <button
                        type="button"
                        className="mb-2 btn-soft-primary btn-sm btn-hover"
                        style={{ borderRadius: "5px" }}
                        onClick={() => templatePreview(3)}>
                        <i className="mdi mdi-eye"></i> Xem trước
                      </button>
                      <div className="btn-downloadpdf btn-hover">
                        <i className="uil uil-import" style={{ color: "white" }}></i>
                        <PDFDownloadLink
                          document={<TemplateBasic3 data={dataPdf} />}
                          fileName={filenamePdf}
                          style={{
                            textDecoration: "none",
                            padding: "10px",
                            color: "#FFFFFF",
                            backgroundColor: "#02af74",
                            border: "none",
                            borderRadius: "5px",
                            width: "100px",
                          }}
                        >
                          {({ blob, url, loading, error }) =>
                            (loading && templateId == 0) ? "Loading..." : "Tải xuống CV"
                          }
                        </PDFDownloadLink>
                      </div>
                    </Row>
                  </div>
                </div>
              </Col>
              <Col lg={3} md={6}>
                <div className="blog-box card p-2 mt-3" style={{ width: '200px', height: '300px' }}>
                  <div className="blog-img position-relative overflow-hidden">
                    <img src={template4} alt="" className="img-fluid" />
                    <div className="bg-overlay"></div>
                  </div>
                  <div className="card-body">
                    <Row className="align-items-center">
                      <button
                        type="button"
                        className="mb-2 btn-soft-primary btn-sm btn-hover"
                        style={{ borderRadius: "5px" }}
                        onClick={() => templatePreview(4)}>
                        <i className="mdi mdi-eye"></i> Xem trước
                      </button>
                      <div className="btn-downloadpdf btn-hover">
                        <i className="uil uil-import" style={{ color: "white" }}></i>
                        <PDFDownloadLink
                          document={<TemplateBasic4 data={dataPdf} />}
                          fileName={filenamePdf}
                          style={{
                            textDecoration: "none",
                            padding: "10px",
                            color: "#FFFFFF",
                            backgroundColor: "#02af74",
                            border: "none",
                            borderRadius: "5px",
                            width: "100px",
                          }}
                        >
                          {({ blob, url, loading, error }) =>
                            (loading && templateId == 0) ? "Loading..." : "Tải xuống CV"
                          }
                        </PDFDownloadLink>
                      </div>
                    </Row>
                  </div>
                </div>
              </Col>
            </Row>
          </ModalBody>
        </div>
      </Modal>

      <Modal isOpen={modalPreview} toggle={openModalPreview} role="dialog" centered backdrop="static" size="xl">
        <div className="modal-content">
          <div className="modal-header" style={{ height: "30px" }}>
            <button
              type="button"
              className="btn-close"
              onClick={openModalPreview}
            ></button>
          </div>
          <ModalBody style={{ height: "700px" }}>
            <Row className="align-items-center">
              <PDFViewer style={{ width: "100%", height: "665px" }}>
                {templateId == 1 && <TemplateBasic1 data={dataPdf} />}
                {templateId == 2 && <TemplateBasic2 data={dataPdf} />}
                {templateId == 3 && <TemplateBasic3 data={dataPdf} />}
                {templateId == 4 && <TemplateBasic4 data={dataPdf} />}
              </PDFViewer>
            </Row>
          </ModalBody>
        </div>
      </Modal>
    </React.Fragment >
  );
};

export default RightSideContent;