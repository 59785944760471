import Moment from 'moment';

export const checkJobPostTime = (create_date) => {
    let jobPostTime = "";
    if (create_date) {
        let dateNow = new Date();
        let createDate = new Date(create_date);
        let postTime = dateNow.getTime() - createDate.getTime();
        let days = Math.floor(postTime / (1000 * 60 * 60 * 24));
        let hours = Math.floor((postTime % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
        let minutes = Math.floor((postTime % (1000 * 60 * 60)) / (1000 * 60));
        let seconds = Math.floor((postTime % (1000 * 60)) / 1000);
        if (Moment(new Date).format("YYYYMMDD") == Moment(create_date).format("YYYYMMDD")) {
            if (Moment(new Date).format("HH") == Moment(create_date).format("HH")) {
                if (Moment(new Date).format("mm") == Moment(create_date).format("mm")) {
                    jobPostTime = seconds + " giây trước";
                } else {
                    jobPostTime = minutes + " phút trước";
                }
            } else {
                jobPostTime = hours + " giờ trước";
            }
        } else {
            if (Moment(new Date).format("YYYYMMDD") < Moment(create_date).format("YYYYMMDD")) {
                jobPostTime = "Hết hạn";
            } else {
                (days == 0 && hours < 24) ? (jobPostTime = hours + " giờ trước") : (jobPostTime = days + " ngày trước");
            }
        }
        return jobPostTime;
    }
    else {
        return "";
    }
}